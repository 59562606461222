import { TypeOfT } from "utils/type/translations/Translation.type";
import { validZipCode } from "../validator";

const quoteValidation = (t?: TypeOfT) => ({
    notes: () => ({
        required: '依頼内容を入力してください。',
    }),
    dateTime: () => ({
        required: '工事希望日を入力してください。',
    }),
    zipCode : () =>({
        required: '現場：郵便番号を入力してください。',
        validate: {
            regex: validZipCode('zipcode', t!),
        }
    }),
    title: () => ({
        required: 'タイトルを入力してください。',
    }),
    content: () => ({
        required: '依頼内容を入力してください。',
    }),
    address: () => ({
        required: '住所を入力してください。',
    }),
    type: () => ({
        required: 'サービスを選択してください。',
    }),
    company: () => ({
        required: '他社連携会社を選択してください。',
    }),
});

export default quoteValidation;
