import {
    Button,
    Drawer,
    Upload,
    UploadProps,
    message,
    Spin,
    Input,
    DatePicker,
    ConfigProvider,
    Modal,
} from 'antd';
import {
    FocusEventHandler,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
    useRef,
} from 'react';
import jaJP from 'antd/es/locale/ja_JP';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CloseOutlined, DeleteOutlined } from '@mui/icons-material';
import cloudUploadIcon from 'assets/icons/cloudUpload.svg';
import uploadIcon from 'assets/icons/addImageFile.svg';
import warningIcon from 'assets/icons/WarningCircle.svg';
import { Controller, useForm } from 'react-hook-form';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import { QuoteStatusEnum } from 'utils/enums';
import { handleFilter } from 'utils/helper/filterDropdown';
import styled from 'styled-components';
import { UploadChangeParam, RcFile, UploadFile } from 'antd/lib/upload';
import pdfFileIcon from 'assets/icons/pdf-file.svg';
import quoteValidation from 'utils/validation/registers/quote';
import quoteApi from 'services/quoteApi';
import { alertError, alertSuccess, alertSuccessPopup, validateZipcode } from 'utils/helper/appHelper';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import { validZipCode } from 'utils/validation/registers/validator';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { PatternFormat } from 'react-number-format';
import { fullWidthNumConvert } from 'pages/invoice';
import InputMask from 'react-input-mask-format';
import { InvoiceFormStyles } from './styles';
import invoiceValidation from 'utils/validation/registers/invoice';
import PlusIcon from 'assets/icons/white-plus.svg'
import RedTrash from 'assets/icons/Trash.svg';
import invoiceApi from 'services/invoiceApi';

const { TextArea } = Input;
const digit = /[0-9０-９]/;
const mask = [digit, digit, digit, "-", digit, digit, digit, digit];
const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
}));
const typeOptions: SelectSearchOption[] = [
    { value: 'banking', name: '銀行振り込み' },
    { value: 'cash', name: '現金' },
];

export interface InvoiceController {
    view: (id: number) => void;
}

const ModalInvoiceCustoms = styled(Modal)`
    .ant-modal-header {
        padding: 0px !important;
    }
    .ant-modal-content {
        min-height: 700px !important;
    }
`;

const UploadCustom = styled(Upload) <{ $isFull?: boolean }>`
    .ant-upload-list-picture-card-container:nth-child(
            ${(props) => (props.$isFull ? '3n' : '3n + 2')}
        ) {
        margin-right: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
        border: none;
        padding: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info {
        border-radius: 6.55px;
        border: 1px solid #dcdcdc;
    }
    .ant-upload-list {
        width: 332px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    .ant-upload-select-picture-card {
        order: -1;
    }
`;


interface IProps { }

const InvoiceController = forwardRef<InvoiceController, IProps>((props, ref) => {
    const {
        control,
        setValue,
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        watch,
        getValues,
        setError,
        reset
    } = useForm();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('id');
    const [invoice, setInvoice]: any = useState()
    const handleClose = () => {
        clearErrors();
        navigate('');
        reset()
        setArrItem([1])
        setInvoice()
    };
    const [arrItem, setArrItem] = useState([1])
    const [totalAfterTax, setTotalAfterTax] = useState(0)
    const submitData = async (value: any, evt: any) => {
        const arrTmp: any = []
        let total = 0
        for (let i = 0; i < value.type.length; i++) {
            if (value.totalM[i] && value.type[i] && value.date_deadline[i]) {
                total = total + Number(value.totalM[i].replace(/,/g, ''))
                arrTmp.push({
                    payment_type: value.type[i],
                    payment_date: moment(value.date_deadline[i]).format('YYYY-MM-DD'),
                    payment_amount: Number(value.totalM[i].replace(/,/g, '')),
                })
            }
            if (Number(value.totalM[i].replace(/,/g, '')) < 50) {
                alertError('各支払い額は、50円以上でなければなりません。')
                return
            }
        }
        if (total > 50 && total <= totalAfterTax) {
            const body: any = {
                invoice_id: id,
                invoice_deposits_attributes: arrTmp
            }
            try {
                if (invoice && invoice.invoice_deposits.length > 0) {
                    const res = await invoiceApi.updateInvoicePay(user, body)
                    if (res) {
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        handleClose()
                    }
                } else {
                    const res = await invoiceApi.createInvoicePay(user, body)
                    if (res) {
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        handleClose()
                    }
                }
            } catch (error) {
                console.log('error', error)
            }
        } else {
            alertError("各支払額は50円以上である必要があります。")
        }
    }
    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        }
    };
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const getDetail = async (arrEdit?) => {
        try {
            const res = await invoiceApi.getInvoiceDetail(user, id)
            if (res) {
                const data = res.data.invoice
                setInvoice(data)
                setValue('companyName', data.company_name)
                setValue('address', data.company_address)
                setValue('bankAccount', data.company_bank_account)
                setValue('customer', data.customer_name)
                setValue('address2', data.customer_address)
                setValue('callNum', data.invoice_number)
                setValue('total', data.total_after_tax.toFixed(2))
                setValue('totalDeposit', data.total_deposit)
                if (data.payment_date) {
                    setValue('paymentDate', moment(data.payment_date))
                }
                if (data.invoice_date) {
                    setValue('date', moment(data.invoice_date))
                }
                setTotalAfterTax(Number(data.total_after_tax))
                let arrTmp: any = []
                if (arrEdit) {
                    arrTmp = [...arrEdit]
                } else {
                    arrTmp = [...arrItem]
                }
                if (data.invoice_deposits.length > 0) {
                    const resCount = data.invoice_deposits.length > 1 ? 1 : 0
                    for (let i = 0; i < data.invoice_deposits.length; i++) {
                        const countTmp = arrTmp[arrTmp.length - 1] || 0
                        arrTmp.push(countTmp + 1)
                        setValue(`date_deadline.${i}`, data.invoice_deposits[i] ? new Date(data.invoice_deposits[i].payment_date) : '')
                        setValue(`totalM.${i}`, data.invoice_deposits[i] ? String(data.invoice_deposits[i].payment_amount.toLocaleString('en-US')) : '')
                        setValue(`type.${i}`, data.invoice_deposits[i] ? data.invoice_deposits[i].payment_type : '')
                    }
                }
                setArrItem(arrTmp)

            }
        } catch (error) {
            console.log('error', error)
        }
    }
    useEffect(() => {
        if (id) {
            setValue('type.0', typeOptions[0].value);
            getDetail([])
        }
    }, [id])
    const onDelete = (index) => {
        const filterArrItem = arrItem.filter((item, ind) => ind !== index)
        const totalMFilter = getValues('totalM').filter((item, ind) => ind !== index)
        const date_deadlineFilter = getValues('date_deadline').filter((item, ind) => ind !== index)
        const typeFilter = getValues('type').filter((item, ind) => ind !== index)
        const arrTmp: any = []
        for (let i = 0; i < filterArrItem.length; i++) {
            const countTmp = arrTmp[arrTmp.length - 1] || 0
            arrTmp.push(countTmp + 1)
        }
        setArrItem([...arrTmp])
        setValue('totalM', [...totalMFilter])
        setValue('date_deadline', [...date_deadlineFilter])
        setValue('type', [...typeFilter])
        // if (!getValues(`quantity.${arrTmp.length - 1}`)) {
        //     setValue(`content.${arrTmp.length - 1}`, '')
        //     setValue(`unit.${arrTmp.length - 1}`, '')
        //     setValue(`quantity.${arrTmp.length - 1}`, '')
        //     setValue(`price.${arrTmp.length - 1}`, '')
        //     setValue(`tax.${arrTmp.length - 1}`, '')
        // }
    }

    const onAddArr = () => {
        // if (getValues('content')[arrItem.length - 1] && getValues('unit')[arrItem.length - 1] && getValues('quantity')[arrItem.length - 1]
        //     && getValues('price')[arrItem.length - 1] && getValues('tax')[arrItem.length - 1]) {
        //     const arrTmp = [...arrItem]
        //     arrTmp.push(arrItem[arrItem.length - 1] + 1)
        //     setArrItem(arrTmp)
        // }
        const arrTmp = [...arrItem]
        if (arrItem.length > 0) {
            arrTmp.push(arrItem[arrItem.length - 1] + 1)
            setValue(`type.${arrTmp.length - 1}`, typeOptions[0].value)
        } else {
            arrTmp.push(1)
        }
        setArrItem(arrTmp)
    }

    return (
        <ModalInvoiceCustoms
            width={600}
            title={
                <div className="flex justify-between items-center bg-[#215493] text-[white] py-[20px] px-[15px]">
                    <span>請求書のお支払い</span>
                    <Button shape="circle" type="text" onClick={handleClose}>
                        <CloseOutlined style={{ color: 'white' }} />
                    </Button>
                </div>
            }
            closable={false}
            destroyOnClose
            footer={null}
            open={Boolean(id)}
            onCancel={handleClose}
        // headerStyle={{ background: '#215493' }}
        >
            <ConfigProvider locale={jaJP}>
                <InvoiceFormStyles>
                    <p style={{ fontSize: '14px', fontWeight: 'bold' }} >
                        お支払い情報
                    </p>
                    <form onSubmit={handleSubmit(submitData)}>
                        <div className="w-full flex flex-col gap-y-[14px]">
                            <div className="flex flex-col w-full">
                                <Controller
                                    name="customer"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                お客様
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'customer',
                                                    invoiceValidation().customer(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled
                                            ></input>
                                            {errors.customer && (
                                                <span className="error">
                                                    {errors.customer.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="address"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                住所
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'address',
                                                    invoiceValidation().address(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled
                                            ></input>
                                            {errors.address && (
                                                <span className="error">
                                                    {errors.address.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="callNum"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                請求番号
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'callNum',
                                                    invoiceValidation().callNum(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled
                                            ></input>
                                            {errors.callNum && (
                                                <span className="error">
                                                    {errors.callNum.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <Controller
                                    name="date"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div>
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">*</span>
                                                請求日
                                            </p>
                                            <DatePicker
                                                {...register(
                                                    'date',
                                                    invoiceValidation().date(),
                                                )} {...field} format={'YYYY年MM月DD日'}
                                                onBlur={handleValidateWhitespace}
                                                value={field.value ? moment(field.value) : null}
                                                disabled
                                                placeholder='yyyy年mm月dd日' style={{ width: '100%', height: '40px' }} />
                                            <br />
                                            {errors.date && (
                                                <span className="error">
                                                    {errors.date.message?.toString()}
                                                </span>
                                            )}
                                        </div>

                                    )}
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="paymentDate"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div>
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">*</span>
                                                振込期限
                                            </p>
                                            <DatePicker
                                                {...register(
                                                    'paymentDate',
                                                    invoiceValidation().paymentDate(),
                                                )} {...field} format={'YYYY年MM月DD日'}
                                                onBlur={handleValidateWhitespace}
                                                value={field.value ? moment(field.value) : null}
                                                disabled
                                                placeholder='yyyy年mm月dd日' style={{ width: '100%', height: '40px' }} />
                                            <br />
                                            {errors.paymentDate && (
                                                <span className="error">
                                                    {errors.paymentDate.message?.toString()}
                                                </span>
                                            )}
                                        </div>

                                    )}
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="total"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                請求番号
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'total',
                                                    invoiceValidation().total(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled
                                            ></input>
                                            {errors.total && (
                                                <span className="error">
                                                    {errors.total.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="totalDeposit"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                入金金額の合計
                                            </p>
                                            {/* <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'totalDeposit',
                                                    invoiceValidation().totalDeposit(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                            ></input> */}
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox"
                                                {...register(
                                                    'totalDeposit',
                                                    invoiceValidation().totalDeposit(),
                                                )}
                                                // onBlur={
                                                //     handleValidateWhitespace
                                                // }
                                                disabled
                                                onBlur={(evt) => {
                                                    setValue('totalDeposit', Number(fullWidthNumConvert(evt.target.value)).toLocaleString('en-US'))
                                                    // handleValidateWhitespace(evt)
                                                    setArrItem([...arrItem])
                                                }}
                                                value={field.value}
                                                onChange={(evt) => {
                                                    let stringWithoutCommas = evt.target.value.replace(/,/g, '');
                                                    if (stringWithoutCommas.match("^[0-9０-９.]+$") || stringWithoutCommas === '') {
                                                        const newVal = fullWidthNumConvert(stringWithoutCommas)
                                                        field.onChange(stringWithoutCommas);
                                                        setValue('totalDeposit', stringWithoutCommas)
                                                    }
                                                }}
                                            />
                                            {errors.totalDeposit && (
                                                <span className="error">
                                                    {errors.totalDeposit.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <p style={{ fontSize: '14px', fontWeight: 'bold' }} >
                                お支払い履歴
                            </p>
                            <div className='flex flex-row'>
                                <div className='w-1/3' >
                                    <p style={{ fontSize: '14px' }} >
                                        合計
                                    </p>
                                </div>
                                <div className='w-1/3' >
                                    <p style={{ fontSize: '14px' }} >
                                        振込期限
                                    </p>
                                </div>
                                <div className='w-1/4' >
                                    <p style={{ fontSize: '14px' }} >
                                        タイプ
                                    </p>
                                </div>
                                <div className='w-1/12' />
                            </div>
                            {arrItem.map((row, ind) => {
                                if (ind < arrItem.length) {
                                    let price = getValues(`totalM.${ind}`) || ''
                                    if (String(price).includes(',')) {
                                        price = price.replace(/,/g, '')
                                    }
                                }
                                return (
                                    <div className='flex flex-row'>
                                        <div className='w-1/3' >
                                            <p style={{ fontSize: '14px' }} >
                                                <Controller
                                                    name={`totalM.${ind}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Input
                                                            autoComplete="off"
                                                            type="text"
                                                            className="textBox"
                                                            style={{ width: '95%' }}
                                                            {...register(
                                                                `totalM.${ind}`
                                                            )}
                                                            // onBlur={
                                                            //     handleValidateWhitespace
                                                            // }
                                                            onBlur={(evt) => {
                                                                setValue(`totalM.${ind}`, Number(fullWidthNumConvert(evt.target.value)).toLocaleString('en-US'))
                                                                // handleValidateWhitespace(evt)
                                                                setArrItem([...arrItem])
                                                            }}
                                                            value={field.value}
                                                            onChange={(evt) => {
                                                                let stringWithoutCommas = evt.target.value.replace(/,/g, '');
                                                                stringWithoutCommas = evt.target.value.replace(/[a-zA-Z]/g, '');
                                                                if (stringWithoutCommas.match("^[0-9０-９.]+$") || stringWithoutCommas === '') {
                                                                    const newVal = fullWidthNumConvert(stringWithoutCommas)
                                                                    field.onChange(stringWithoutCommas);
                                                                    setValue(`totalM.${ind}`, stringWithoutCommas)
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </p>
                                        </div>
                                        <div className='w-1/3' >
                                            <p style={{ fontSize: '14px' }} >
                                                <Controller
                                                    name={`date_deadline.${ind}`}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <div>
                                                            <DatePicker
                                                                {...register(
                                                                    `date_deadline.${ind}`
                                                                )} {...field} format={'YYYY年MM月DD日'}
                                                                onBlur={handleValidateWhitespace}
                                                                value={field.value ? moment(field.value) : null}
                                                                placeholder='yyyy年mm月dd日' style={{ width: '95%', height: '36px' }} />
                                                            <br />
                                                            {errors.date_deadline && (
                                                                <span className="error">
                                                                    {errors.date_deadline.message?.toString()}
                                                                </span>
                                                            )}
                                                        </div>

                                                    )}
                                                />
                                            </p>
                                        </div>
                                        <div className='w-1/4' >
                                            <p style={{ fontSize: '14px' }} >
                                                <Controller
                                                    name={`type.${ind}`}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <SelectSearch
                                                            options={typeOptions}
                                                            search
                                                            filterOptions={handleFilter}
                                                            {...register(
                                                                `type.${ind}`,
                                                                quoteValidation().type(),
                                                            )}
                                                            {...field}
                                                            emptyMessage={'該当なし'}
                                                            placeholder=""
                                                        />
                                                    )}
                                                />
                                            </p>
                                        </div>
                                        <div className='w-1/12' >
                                            <div className='flex flex-row justify-center ml-[10px] p-[5px] cursor-pointer'
                                                onClick={() => onDelete(ind)}
                                                style={{ border: '1px solid #D83535', borderRadius: '8px', width: '40px', height: '36px' }}>
                                                <img src={RedTrash} alt="" />
                                            </div>

                                        </div>
                                    </div>
                                )
                            })}

                            <Button className='w-[134px] h-[38px] mx-auto text-white bg-[#215493]' onClick={onAddArr}
                                style={{ borderRadius: '8px', background: '#215493', gap: '8px', display: 'flex', flexDirection: 'row', }} >
                                <img src={PlusIcon} alt='plus' height={16} width={16} className='mt-[3px]' /> 入金追加</Button>
                        </div>
                        <div className='bg-[#fff] flex flex-row justify-end' style={{ padding: '16px 24px 16px 24px' }} >
                            <div
                                className="d-flex flex-row text-lg"
                                style={{
                                    marginTop: '14px',
                                }}
                            >
                                <button
                                    className="modal-create-user-button1 d-flex flex-row w-[240px]"
                                    style={{ width: '240px', borderRadius: '0px', border: '1px solid #D9D9D9', color: '#000000D9' }}
                                    type="button"
                                    onClick={() => handleClose()}
                                >
                                    キャンセル
                                </button>

                                <button
                                    className="modal-create-user-button2 d-flex flex-row mx-[15px] w-[240px]"
                                    style={{
                                        width: '240px', borderRadius: '0px',
                                        background: `${invoice?.status === 'paid' ? '#CCCCCC' : ''}`
                                    }}
                                    disabled={invoice?.status === 'paid'}
                                    // disabled={getValues('companyName') && getValues('address') && getValues('bankAccount') && getValues('paymentDate')
                                    // && getValues('date') && getValues('customer') && getValues('address2') && getValues('callNum') ? false : true}
                                    type="submit"
                                >
                                    保存
                                </button>
                            </div>
                        </div>
                    </form>
                </InvoiceFormStyles>

            </ConfigProvider>
        </ModalInvoiceCustoms>
    )
});

const StyleMaskInput = styled(InputMask)({
    padding: '5px',
    border: '1px solid rgb(205, 209, 213)',
    width: '70%'
})

export default InvoiceController;
