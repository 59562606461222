import axios from 'axios';
import queryString from 'query-string';
import { alertPopup } from 'utils/helper/appHelper';
const quoteUrl = process.env.REACT_APP_SERVER_API;
const lineToken = localStorage.getItem('line:accessToken');

export const axiosClient = axios.create({
    baseURL: quoteUrl,
    headers: {
        'content-type': 'application/json',
        'Device-Type': 'Webapp',
        version: 9999,
        Authorization: `Bearer ${lineToken}`,
    },
    // paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.response.use(
    (response: any) => {
        return response;
    },
    (error: any) => {
        // Handle errors
        let errorMessage = error;
        if (error.response) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('line:accessToken');
                window.location.href = `${process.env.REACT_APP_SERVER_API_LINE}/auth/line`;
            }
            errorMessage =
                error.response.data?.message || error.response.data?.messages;
        }
        const err = new Error(errorMessage);
        if (err.message.includes('Network Error')) {
            err.message = 'ネットワークエラー';
            alertPopup({
                type: 'error',
                title: 'ネットワークエラー',
                confirmButtonText: 'キャンセル',
            });
        }
        err.name = '';
        throw err;
    },
);

const lineUrl = 'https://api-conaffetto-dev.codeaplha.biz/line/api/';
class QuoteApi {
    createSPQuote = (idToken: any, body: any) => {
        const url = 'line/quotes';
        // const config: any = {
        //   headers: { Authorization: `Bearer ${idToken}` }
        // }
        return axiosClient.post(url, body);
    };
    updateSPQuote = (idToken: any, body: any, id: any) => {
        const url = `line/quotes/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.put(url, body, config);
    };

    getHistoryQuote = (idToken: any, param: any) => {
        const qs = queryString.stringify(param);
        const url = `quotes?${qs}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };

    updateQuote = (idToken: any, body: any, id: any) => {
        const url = `quotes/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.put(url, body, config);
    };
    getQuoteHistory = (status, idToken: any, page, text) => {
        let url = `${
            process.env.REACT_APP_SERVER_API_LINE
        }/line/api/quotes?status=${status}&page=${page}${
            text ? `&filter_value=${text}` : ''
        }`;
        if (status === 'all') {
            url = `${
                process.env.REACT_APP_SERVER_API_LINE
            }/line/api/quotes?page=${page}${
                text ? `&filter_value=${text}` : ''
            }`;
        }
        // if (status === "initial") {
        //   url += `&status=initial`;
        // }
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };
    getQuoteDetail = (id) => {
        let url = `quotes/${id}`;
        return axiosClient.get(url);
    };

    getSPQuoteDetail = (idToken: any, id: any) => {
        const url = `quotes/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };

    deleteQuote = (idToken: any, id: any) => {
        const url = `quotes/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.delete(url, config);
    };

    getCompanies = (idToken: any) => {
        const url = 'cooperative_companies?page=1&per_page=20&free_word';
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };

    geAlltCompanies = (idToken: any) => {
        const url = 'cooperative_companies?page=1&per_page=10000';
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };
}
const quoteApi = new QuoteApi();
export default quoteApi;
