import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import invoiceApi from 'services/invoiceApi';
import defaultAvatar from 'assets/avatar-default.png';
import { QuoteTypeEnum } from 'utils/enums';
import PencilIcon from 'assets/icons/PencilSimpleLine.svg';
import RedTrash from 'assets/icons/Trash.svg';
import InvoiceImg from 'assets/icons/invoice-img.svg';
import { Box, TextField, Button } from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';
import { useModal } from 'react-hooks-use-modal';
import { CloseOutlined } from '@mui/icons-material';
import ExclamationIcon from 'assets/icons/exclamation.svg';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { alertError, alertSuccess } from 'utils/helper/appHelper';
import InvoiceController from './InvoiceCotroller';

const { RangePicker } = DatePicker;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#E8E8E8',
        color: '#1C1E21',
        fontWeight: 600,
        padding: '12px 14px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '8px 14px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const LeftOutlinedCustom = styled(LeftOutlined)``;

interface IInvoiceData {
    id: number;
    company_address: string;
    company_bank_account: string;
    company_name: string;
    created_at: string;
    customer_address: string;
    customer_name: string;
    invoice_date: string;
    invoice_details: unknown[];
    invoice_number: string;
    line_user_avatar?: string | null;
    line_user_name: string;
    payment_date: string;
    payment_type?: string | null;
    quote_id: number;
    status: string;
    total_after_tax?: number;
    total_before_tax?: number;
    total_tax?: number;
    type_of_quote: string;
    updated_at?: string;
    invoice_deposits?
    total_deposit?
}

interface ISearchValues {
    filter_value: string;
    invoice_start_date: string;
    invoice_end_date: string;
    payment_start_date: string;
    payment_end_date: string;
}

const initialSearchValues: ISearchValues = {
    filter_value: '',
    invoice_start_date: '',
    invoice_end_date: '',
    payment_start_date: '',
    payment_end_date: '',
};

const Invoices: React.FC = () => {
    const [invoices, setInvoices] = useState<IInvoiceData[]>([]);
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [statusPrev, setStatusPrev] = useState(false);
    const [statusNext, setStatusNext] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState<IInvoiceData | null>(
        null,
    );
    const location = useLocation()
    const PER_PAGE = 10;
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: false,
        },
    );
    const [searchValues, setSearchValues] =
        useState<ISearchValues>(initialSearchValues);
    const [submitValues, setSubmitValues] =
        useState<ISearchValues>(initialSearchValues);

    const setPagePrev = () => {
        if (page > 1) {
            setPage(page - 1);
            setStatusNext(false);
            setStatusPrev(true);
        }
    };
    const setPageNext = () => {
        if (page < totalPage) {
            setPage(page + 1);
            setStatusNext(true);
            setStatusPrev(false);
        }
    };

    const fetchInvoices = async () => {
        try {
            const response = await invoiceApi.getInvoices(user, {
                page: page,
                per_page: PER_PAGE,
                filter_value: submitValues.filter_value,
                invoice_start_date: submitValues.invoice_start_date,
                invoice_end_date: submitValues.invoice_end_date,
                payment_start_date: submitValues.payment_start_date,
                payment_end_date: submitValues.payment_end_date,
            });
            const { data } = response;
            if (data) {
                const totalCount = data?.meta?.total_count;
                const totalInteger = Math.floor(totalCount / PER_PAGE);
                const totalSurplus = totalCount % PER_PAGE;
                const totalPageCustom =
                    totalSurplus >= 1 ? totalInteger + 1 : totalInteger;
                setTotalPage(totalPageCustom);
                setInvoices(data.invoices);
            }
        } catch (error) { }
    };

    const handleNumberFormat = (number: number) => {
        return new Intl.NumberFormat('ja-JP', {
            style: 'currency',
            currency: 'JPY',
        }).format(number);
    };

    useEffect(() => {
        if (!location.search) {
            fetchInvoices();
        }
    }, [page, submitValues,location.search]);

    const handleSearchInvoices = () => {
        setPage(1);
        setSubmitValues((prev) => ({
            ...prev,
            filter_value: searchValues.filter_value,
            invoice_start_date: searchValues.invoice_start_date,
            invoice_end_date: searchValues.invoice_end_date,
            payment_start_date: searchValues.payment_start_date,
            payment_end_date: searchValues.payment_end_date,
        }));
    };

    const handleDeleteInvoice = async () => {
        if (!selectedInvoice) {
            return;
        }
        try {
            const response = await invoiceApi.deleteInvoice(
                user,
                selectedInvoice.id,
            );
            const { data } = response;
            if (data && data.status === 'success') {
                fetchInvoices();
                alertSuccess(
                    `${selectedInvoice.invoice_number}を削除しました。`,
                );
            }
        } catch (error) {
            alertError('');
        } finally {
            closeDelete();
            setSelectedInvoice(null);
        }
    };

    const onImageError = (e) => {
        e.target.src = defaultAvatar
    }

    const deletePaymentInvoice = async (id) => {
        const res = await invoiceApi.deleteInvoicePay(user, id)
        if (res) {
            alertSuccess('請求書の支払額の削除が成功しました。')
            fetchInvoices();
        }
    }

    return (
        <>
            <div className="container-worker d-flex flex-row">
                <div className="worker-content d-flex flex-column">
                    <div className="filterSection flex items-end mb-3">
                        <Box
                            sx={{
                                width: '100%',
                                maxWidth: '250px',
                                display: 'flex',
                                '.input-search': {
                                    border: '1px solid #cdd1d5',
                                    borderTopLeftRadius: '2px',
                                    borderBottomLeftRadius: '2px',
                                    marginBottom: '14.5px',
                                    paddingLeft: '14px',
                                },
                                '.MuiTextField-root': {
                                    border: '1px solid #cdd1d5',
                                    width: '100%',
                                    borderTopLeftRadius: '2px',
                                    borderBottomLeftRadius: '2px',
                                    paddingLeft: '14px',
                                    // marginRight: '12.5px',
                                },
                                '.MuiInputBase-input': {
                                    height: '30px',
                                    padding: '0px !important',
                                },
                                '.MuiButtonBase-root': {
                                    minWidth: '0px',
                                },
                            }}
                        >
                            <TextField
                                autoComplete="off"
                                placeholder="検索"
                                type="text"
                                value={searchValues.filter_value}
                                onChange={(evt) =>
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        filter_value: evt.target.value,
                                    }))
                                }
                            />
                        </Box>

                        <Box sx={{ marginLeft: '10px' }}>
                            <div className="mb-1">日付</div>
                            <RangePicker
                                placeholder={['開始日', '終了日']}
                                onChange={(dates, dateStrings) => {
                                    const [start, end] = dateStrings;
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        invoice_start_date: start,
                                        invoice_end_date: end,
                                    }));
                                }}
                            />
                        </Box>

                        <Box sx={{ marginLeft: '10px' }}>
                            <div className="mb-1">振込期限</div>
                            <RangePicker
                                placeholder={['開始日', '終了日']}
                                onChange={(dates, dateStrings) => {
                                    const [start, end] = dateStrings;
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        payment_start_date: start,
                                        payment_end_date: end,
                                    }));
                                }}
                            />
                        </Box>

                        <button
                            onClick={handleSearchInvoices}
                            className="modal-create-user-button2 !w-[70px] !h-[32px] !rounded-[3px] ml-3"
                        >
                            検索
                        </button>
                    </div>
                    {/* <div className="table-workers d-flex flex-column">
                        <div className="table-content d-flex flex-column">
                            
                        </div>
                    </div> */}
                    <TableContainer>
                        <Table
                            sx={{ minWidth: '150%', overflowX: 'scroll' }}
                            aria-label="customized table "

                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        className="text-overflow1 !text-left"
                                        style={{ width: '18%' }}
                                    >
                                        <div>Lineアカウント</div>
                                    </StyledTableCell>

                                    <StyledTableCell
                                        className="text-overflow1 !text-left"
                                        style={{ width: '11%' }}
                                    >
                                        <div>請求番号</div>
                                    </StyledTableCell>

                                    <StyledTableCell
                                        className="text-overflow1 !text-left"
                                        style={{ width: '15%' }}
                                    >
                                        <div>請求日</div>
                                    </StyledTableCell>

                                    <StyledTableCell
                                        className="text-overflow1 !text-left"
                                        style={{ width: '15%' }}
                                    >
                                        <div>振込期限</div>
                                    </StyledTableCell>

                                    <StyledTableCell
                                        className="text-overflow1 !text-left"
                                        style={{ width: '8%' }}
                                    >
                                        <div>タイプ</div>
                                    </StyledTableCell>

                                    <StyledTableCell
                                        className="text-overflow1 !text-left"
                                        style={{ width: '12%' }}
                                    >
                                        <div>合計</div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow1 !text-left"
                                        style={{ width: '12%' }}
                                    >
                                        <div>入金金額の合計</div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        className="text-overflow1 !text-left"
                                        style={{ width: '18%' }}
                                    />
                                    <StyledTableCell
                                        className="text-overflow1 !text-left"
                                        style={{ width: '5%' }}
                                    />
                                    <StyledTableCell
                                        className="text-overflow1 !text-left"
                                        style={{ width: '5%' }}
                                    />
                                    <StyledTableCell
                                        className="text-overflow1"
                                        style={{ width: '8%' }}
                                    >
                                        <div>操作</div>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {invoices.length > 0 &&
                                    invoices.map((row, idx) => {
                                        return (
                                            <StyledTableRow
                                                key={row.id}
                                            >
                                                <StyledTableCell
                                                    className="!text-left"
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                        width: '18%',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display:
                                                                'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                row.line_user_avatar ||
                                                                defaultAvatar
                                                            }
                                                            onError={onImageError}
                                                            alt={`${row.line_user_name} profile photo`}
                                                            className="rounded-[50%] w-[40px] h-[40px] mr-[5px]"
                                                        />
                                                        <span>
                                                            {
                                                                row.line_user_name
                                                            }
                                                        </span>
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="!text-left"
                                                    style={{
                                                        width: '11%',
                                                    }}
                                                >
                                                    <div>
                                                        {
                                                            row.invoice_number
                                                        }
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="!text-left"
                                                    style={{
                                                        width: '15%',
                                                    }}
                                                >
                                                    <div>
                                                        {row.invoice_date &&
                                                            moment(
                                                                row.invoice_date,
                                                            ).format(
                                                                'YYYY年MM月DD日',
                                                            )}
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="!text-left"
                                                    style={{
                                                        width: '15%',
                                                    }}
                                                >
                                                    <div>
                                                        {row.payment_date &&
                                                            moment(
                                                                row.payment_date,
                                                            ).format(
                                                                'YYYY年MM月DD日',
                                                            )}
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="!text-left"
                                                    style={{
                                                        width: '8%',
                                                    }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {
                                                            QuoteTypeEnum[
                                                                row
                                                                    .type_of_quote
                                                            ]?.title
                                                        }
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="!text-left"
                                                    style={{
                                                        width: '12%',
                                                    }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {handleNumberFormat(
                                                            row.total_after_tax ||
                                                            0,
                                                        )}
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="!text-left"
                                                    style={{
                                                        width: '13%',
                                                    }}
                                                >
                                                    <div
                                                        className="text-overflow1"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {row.status ===
                                                            'paid' && !row.total_deposit &&
                                                            handleNumberFormat(
                                                                row.total_after_tax ||
                                                                0,
                                                            )}
                                                        {row.total_deposit && 
                                                        handleNumberFormat(
                                                            row.total_deposit ||
                                                            0,
                                                        )}
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="!text-left"
                                                    style={{
                                                        width: '20%',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {row.invoice_deposits.length > 0 && row.invoice_deposits.map((deposit, index) => (
                                                        <div
                                                        >
                                                            {deposit.payment_date &&
                                                                moment(
                                                                    deposit.payment_date,
                                                                ).format(
                                                                    'YYYY年MM月DD日',
                                                                )}
                                                            <br />
                                                        </div>
                                                    ))}
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="!text-left"
                                                    style={{
                                                        width: '12%',
                                                    }}
                                                >
                                                    {row.invoice_deposits.length > 0 && row.invoice_deposits.map((deposit, index) => (
                                                        <div
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {handleNumberFormat(
                                                                deposit.payment_amount ||
                                                                0,
                                                            )}
                                                            <br />
                                                        </div>
                                                    ))}

                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="!text-left"
                                                    style={{
                                                        width: '5%',
                                                    }}
                                                >
                                                    {row.invoice_deposits.length > 0 && row.invoice_deposits.map((deposit, index) => (
                                                        <Box
                                                            sx={{
                                                                cursor: 'pointer',
                                                                width: 24,
                                                                height: 24,
                                                                margin: '0px 4px',
                                                            }}
                                                            onClick={() => {
                                                                console.log('deposit', deposit)
                                                                deletePaymentInvoice(deposit.id)
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    RedTrash
                                                                }
                                                                alt="red trash icon"
                                                            />
                                                        </Box>
                                                    ))}

                                                </StyledTableCell>

                                                <StyledTableCell
                                                    align="center"
                                                    style={{
                                                        width: '8%',
                                                    }}
                                                >
                                                    <div className="flex justify-center items-center">

                                                        <Box
                                                            sx={{
                                                                cursor: 'pointer',
                                                                width: 24,
                                                                height: 24,
                                                                margin: '0px 8px 0px 4px',
                                                            }}
                                                            onClick={() =>
                                                                navigate(
                                                                    `/invoices?id=${row.id}`,
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    InvoiceImg
                                                                }
                                                                alt="Invoice icon"
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                cursor: 'pointer',
                                                                width: 24,
                                                                height: 24,
                                                                margin: '0px 8px 0px 4px',
                                                            }}
                                                            onClick={() =>
                                                                navigate(
                                                                    `/quote/list/invoice/${row.quote_id}?invoice_id=${row.id}`,
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    PencilIcon
                                                                }
                                                                alt="pencil icon"
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                cursor: 'pointer',
                                                                width: 24,
                                                                height: 24,
                                                                margin: '0px 4px',
                                                            }}
                                                            onClick={() => {
                                                                openDelete();
                                                                setSelectedInvoice(
                                                                    row,
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    RedTrash
                                                                }
                                                                alt="red trash icon"
                                                            />
                                                        </Box>
                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {invoices.length === 0 && (
                            <div className="text-center p-[10px] w-full">
                                データがありません。
                            </div>
                        )}
                    </TableContainer>
                    <div className="flex items-baseline gap-[50px] justify-end mt-[20px] pr-[18px]">
                        <p className="font-normal text-[14px] text-[#666666]">
                            ページ {page} / {totalPage}
                        </p>
                        <div className="flex gap-[25px]">
                            <LeftOutlinedCustom
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black]':
                                            statusPrev && page !== 1,
                                        '!text-[#D3D3D3]': page === 1,
                                    },
                                )}
                                onClick={setPagePrev}
                                disabled
                            />

                            <RightOutlined
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black] inline-flex':
                                            statusNext && page !== totalPage,
                                        '!text-[#D3D3D3]': page === totalPage,
                                    },
                                )}
                                onClick={setPageNext}
                            />
                        </div>
                    </div>
                </div>
                <InvoiceController />
            </div>
            <ModalDelete>
                <div
                    className="d-flex flex-column justify-center !bg-[#ffffff] rounded-[10px] px-[20px]"
                    style={{ width: '449px', height: '285px' }}
                >
                    <div className="flex justify-end">
                        <CloseOutlined
                            onClick={() => {
                                setSelectedInvoice(null);
                                closeDelete();
                            }}
                        />
                    </div>
                    <div className="flex flex-col justify-center items-center pt-[15px] pb-[10px]">
                        <img
                            src={ExclamationIcon}
                            className="w-[80px] h-[80px]"
                            alt="exclamation icon"
                        />
                        <div className="text-[#215493] font-medium text-[16px] pt-[5px]">
                            警告
                        </div>
                        <div className="text-[#3A3B3C] font-normal text-[13px] pt-[5px]">
                            本当に削除しますか?
                        </div>
                        <div
                            className="flex gap-[10px]"
                            style={{ marginTop: '40px' }}
                        >
                            <button
                                className="modal-create-user-button1 d-flex flex-row !w-[147px] !h-[36px] !rounded-[3px]"
                                onClick={() => {
                                    setSelectedInvoice(null);
                                    closeDelete();
                                }}
                            >
                                キャンセル
                            </button>
                            <button
                                onClick={handleDeleteInvoice}
                                className="modal-create-user-button2 d-flex flex-row !w-[147px] !h-[36px] !rounded-[3px]"
                            >
                                削除
                            </button>
                        </div>
                    </div>
                </div>
            </ModalDelete>
        </>
    );
};

export default Invoices;
