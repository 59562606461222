import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import LineApp from './line/index';
import './line/style.scss';
import useDocumentTitle from 'hooks/documentTitle';

const Line = () => {
    useDocumentTitle("コンアフェット発注システム");
    return (
        <Router>
            <LineApp />
        </Router>
    );
};

export default Line;
