import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import quoteApi from 'services/quoteApi';

export type ReceivedProps = Record<never, never>;
const dummyData = {
    user_name: '木村様',
    address: '東京都 台東区三筋３－３０',
    avatar: '/img/woman_avatar.jpg',
    date: '2023-11-30 10:30',
    id: 2,
    desired_date: '',
    status: 'initial',
    quote_type: '内装',
    title: 'タイトル',
    note: '電気・水道・ガス等の設備工事も含めて対応してほしい',
    file_url: null,
    content: '',
    // invoice:null,
    files: [],
    images: [
        {
            id: 1906,
            image_path:
                'https://images.pexels.com/photos/1680247/pexels-photo-1680247.jpeg',
        },
        {
            id: 1907,
            image_path:
                'https://images.pexels.com/photos/1474157/pexels-photo-1474157.jpeg',
        },
        {
            id: 1908,
            image_path:
                'https://images.pexels.com/photos/7688356/pexels-photo-7688356.jpeg',
        },
    ],
    invoice: {
      id: '3'
    },
    desired_from_date: '',
    desired_end_date: ''
};
const useQuoteDetail = (props: ReceivedProps) => {
    const [data, setData] = useState<typeof dummyData | null>(null);
    const params = useParams();
    const { id } = params;

    const fetchData = async () => {
      try {
        const response = await quoteApi.getQuoteDetail(id);
        if (response) {
          const { data } = response;
          setData(data.quote);
        }
      } catch (error) {
        // 
      }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return {
        ...props,
        data,
    };
};

export type Props = ReturnType<typeof useQuoteDetail>;

export default useQuoteDetail;
