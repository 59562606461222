import styled from 'styled-components';

export const InvoiceFormStyles = styled.div`
    height: 100%;

        .textBox {
            width: 100%;
            gap: 8px;
            padding: 10px 12px;
            width: 100%;
            height: 36px;
            background: #ffffff;
            border: 1px solid #cdd1d5;
            border-radius: 2px;
        }

        .select-search {
            .select-search__input {
                border: 1px solid #cdd1d5;
                box-shadow: none;

                &:hover {
                    border-color: #215493;
                }
            }

            .select-search__select {
                .select-search__options {
                    padding-left: 0;
                    .select-search__row {
                        .select-search__option.is-selected {
                            background: #215493;
                        }
                    }
                }
            }
        }
    }
`;
