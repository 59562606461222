import { useState, useEffect } from 'react';
import './workerCreate.css';
import { useNavigate, useLocation } from 'react-router-dom';
import workersService from '../../../services/wokersService';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, Box } from '@mui/material';
import DatePicker from 'react-datepicker';
import calendarIcon from '../../../assets/icons/calendar.png';
import positionService from '../../../services/positionService';
import departmentsService from '../../../services/departmentsService';
import CooperativeCompanyService from '../../../services/cooperativeCompaniesService';
import isEmpty from 'lodash/isEmpty';
import { RootState, AppDispatch, useAppDispatch } from '../../../store/index';
import { useSelector } from 'react-redux';
import userService from 'services/userService';
import { Controller, useForm } from 'react-hook-form';
import { convertErrorMessagesToObject } from 'utils/validation/validatorHelper';
import createWorkerValidation from 'utils/validation/registers/workers/createWorkerValidation';
import { useTranslation } from 'react-i18next';
import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import { convertClientCompaniesToDropdownOptionsCreateProject } from 'utils/helper/clientCompanyHelper';

export const tantouList = [
    {
        value: 'management',
        label: '管理'
    },
    {
        value: 'construction',
        label: '工事'
    },
    {
        value: 'office_work',
        label: '事務'
    },
]

const WorkerCreate = (props) => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const { positionsList } = useSelector(
        (state: RootState) => state.positions,
    );
    const { departmentList } = useSelector(
        (state: RootState) => state.deparments,
    );
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(null);

    const [companyOption, setCompanyOption] = useState<any>([]);

    const [positionOption, setPositionOption] = useState<any>([]);
    const [departmentOption, setDepartmentOption] = useState<any>([]);
    const [tantouOption, setTantouOption] = useState<any>([]);

    const [listGenderValue, setListGenderValue] = useState<any>(null);

    const getListConfiguration = async () => {
        try {
            const response = await userService.getListConfiguration();
            if (response !== null && response !== undefined) {
                setListGenderValue(
                    convertUserConfigToDropdownOptions(
                        response.configurations.worker.gender_types,
                    ),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListDemartment = async () => {
        try {
            const response = await departmentsService.getListDepartments();
            if (response !== null && response !== undefined) {
                setDepartmentOption(
                    convertObjectToDropdownOptions(response.departments),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListCompanies = async () => {
        try {
            const response =
                await CooperativeCompanyService.getListCooperativeCompanies();
            if (response !== null && response !== undefined) {
                setCompanyOption(
                    convertClientCompaniesToDropdownOptionsCreateProject(
                        response.cooperative_companies,
                    ),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListPositions = async () => {
        try {
            const response = await positionService.getListPositions();
            if (response !== null && response !== undefined) {
                setPositionOption(
                    convertObjectToDropdownOptions(response.positions),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const handleCreateWorker = async (data) => {
        return new Promise(async (resolve, reject) => {
            await workersService
                .createWorker(data)
                .then(() => {
                    navigate('/workers');
                })
                .catch((err) => {
                    const response = err?.response;
                    if (!response || typeof response === 'undefined') {
                        window.location.href = '/login';
                        reject(err);
                    }
                    switch (response.status) {
                        case 403:
                            const messages = convertErrorMessagesToObject(
                                {
                                    gender_type: 'gender_type',
                                },
                                response.data?.error,
                            );

                            if (!isEmpty(messages)) {
                                Object.keys(messages).map((key) =>
                                    setError(key, { message: messages[key] }),
                                );
                            } else {
                                setError('name', {
                                    message: response.data?.error,
                                });
                            }
                            break;
                        default:
                    }
                    reject(err);
                });
            resolve(true);
        });
    };

    useEffect(() => {
        getListCompanies();
        getListConfiguration();
        if (positionsList !== undefined && positionsList.length == 0)
            getListPositions();
        else {
            setPositionOption(convertObjectToDropdownOptions(positionsList));
        }
        if (departmentList !== undefined && departmentList.length == 0)
            getListDemartment();
        else {
            setDepartmentOption(convertObjectToDropdownOptions(departmentList));
        }
    }, []);
    return (
        <form
            onSubmit={handleSubmit(handleCreateWorker)}
            className="container-worker-create-detail d-flex flex-row"
        >
            <div className="worker-content d-flex flex-column items-start">
                <div className="worker-create-title d-flex flex-column">
                    社員 新規作成
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>名前</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register('name', createWorkerValidation(t).name())}
                    />
                    {errors.name && (
                        <span className="error">
                            {errors.name.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>フリガナ</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register('kana', createWorkerValidation(t).kana())}
                    />
                    {errors.kana && (
                        <span className="error">
                            {errors.kana.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p style={{ marginTop: 0 }}>性別</p>
                    <Controller
                        name="gender_type"
                        control={control}
                        render={({ field: { value } }) => (
                            <Dropdown
                                className="dropdown-create-user1 worker-create-input"
                                controlClassName="dropdown-control-user"
                                options={listGenderValue}
                                value={{
                                    label: value?.label || '',
                                    value: value?.value || '',
                                }}
                                {...register(
                                    'gender_type',
                                    // createWorkerValidation(t).gender_type()
                                )}
                                onChange={(newValue) => {
                                    if (
                                        value &&
                                        value.value === newValue.value
                                    ) {
                                        setValue('gender_type', undefined);
                                    } else {
                                        setValue('gender_type', newValue);
                                    }
                                }}
                                placeholder=""
                            />
                        )}
                    />
                    {errors.gender_type && (
                        <span className="error">
                            {errors.gender_type.message?.toString()}
                        </span>
                    )}
                </div>
                <Box
                    sx={{
                        '.MuiInputBase-input': {
                            border: '1px solid #cdd1d5 !important',
                        },
                    }}
                    className="input-add-user margin-input d-flex flex-column"
                >
                    <p style={{ marginTop: 0 }}>協力会社</p>
                    <Controller
                        name="cooperative_company_id"
                        // rules={{required: true}}
                        control={control}
                        render={({ field: { value } }) => {
                            return (
                                <Autocomplete
                                    disablePortal
                                    value={{
                                        label: value?.label || '',
                                        value: value?.value || '',
                                    }}
                                    options={companyOption.map((item) => ({
                                        label: item.label,
                                        value: String(item.value),
                                    }))}
                                    getOptionLabel={(option: any) =>
                                        option.label || option.value
                                    }
                                    {...register(
                                        'cooperative_company_id',
                                        // createWorkerValidation(t).cooperative_company_id()
                                    )}
                                    onChange={(event, newValue) => {
                                        setValue(
                                            'cooperative_company_id',
                                            newValue,
                                        );
                                    }}
                                    classes={{
                                        root: 'worker-create-search-select-style worker-create-input',
                                        input: 'worker-create-search-select-style worker-create-input',
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label=""
                                            className="autocomplete-custom-textfield"
                                        />
                                    )}
                                    noOptionsText="該当なし"
                                />
                            );
                        }}
                    />
                    {errors.cooperative_company_id && (
                        <span className="error">
                            {errors.cooperative_company_id.message?.toString()}
                        </span>
                    )}
                </Box>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p style={{ marginTop: 0 }}>部署</p>
                    <Controller
                        name="department_id"
                        control={control}
                        render={({ field: { value } }) => (
                            <Dropdown
                                className="dropdown-create-user1 worker-create-input"
                                controlClassName="dropdown-control-user"
                                options={departmentOption}
                                value={{
                                    label: value?.label || '',
                                    value: value?.value || '',
                                }}
                                {...register(
                                    'department_id',
                                    // createWorkerValidation(t).department_id()
                                )}
                                onChange={(newValue) => {
                                    if (
                                        value &&
                                        value.value === newValue.value
                                    ) {
                                        setValue('department_id', undefined);
                                    } else {
                                        setValue('department_id', newValue);
                                    }
                                }}
                                placeholder=""
                            />
                        )}
                    />
                    {errors.department_id && (
                        <span className="error">
                            {errors.department_id.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p style={{ marginTop: 0 }}>役職</p>
                    <Controller
                        name="position_id"
                        control={control}
                        render={({ field: { value } }) => (
                            <Dropdown
                                className="dropdown-create-user1 worker-create-input"
                                controlClassName="dropdown-control-user"
                                options={positionOption}
                                value={{
                                    label: value?.label || '',
                                    value: value?.value || '',
                                }}
                                {...register(
                                    'position_id',
                                    // createWorkerValidation(t).position_id()
                                )}
                                onChange={(newValue) => {
                                    if (
                                        value &&
                                        value.value === newValue.value
                                    ) {
                                        setValue('position_id', undefined);
                                    } else {
                                        setValue('position_id', newValue);
                                    }
                                }}
                                placeholder=""
                            />
                        )}
                    />
                    {errors.position_id && (
                        <span className="error">
                            {errors.position_id.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>携帯電話</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register(
                            'celphone',
                            createWorkerValidation(t).celphone(),
                        )}
                    />
                    {errors.celphone && (
                        <span className="error">
                            {errors.celphone.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>FAX</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register(
                            'fax',
                            // createWorkerValidation(t).fax()
                        )}
                    />
                    {errors.fax && (
                        <span className="error">
                            {errors.fax.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>email</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register(
                            'email',
                            createWorkerValidation(t).email(),
                        )}
                    />
                    {errors.email && (
                        <span className="error">
                            {errors.email.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>電話番号</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register(
                            'phone',
                            createWorkerValidation(t).phone(),
                        )}
                    />
                    {errors.phone && (
                        <span className="error">
                            {errors.phone.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>郵便番号</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register(
                            'zipcode',
                            createWorkerValidation(t).zipcode(),
                        )}
                    />
                    {errors.zipcode && (
                        <span className="error">
                            {errors.zipcode.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p>住所</p>
                    <input
                        autoComplete="off"
                        type="text"
                        className="worker-create-input"
                        {...register(
                            'address',
                            // createWorkerValidation(t).address()
                        )}
                    />
                    {errors.address && (
                        <span className="error">
                            {errors.address.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="input-add-user margin-input d-flex flex-column">
                    <p>誕生日</p>
                    <div
                        className="worker-create-input"
                        style={{ position: 'relative', maxWidth: '343px' }}
                    >
                        <Controller
                            name="birthday"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    className="worker-create-input"
                                    selected={startDate}
                                    {...register(
                                        'birthday',
                                        // createWorkerValidation(t).birthday()
                                    )}
                                    {...field}
                                    id="birthday"
                                    onChange={(date) => {
                                        field?.onChange(date);
                                        setStartDate(date);
                                    }}
                                    startDate={startDate}
                                    maxDate={new Date()}
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                />
                            )}
                        />
                        <label htmlFor="birthday">
                            <img
                                className="input-date-img"
                                width={'16px'}
                                height={'16px'}
                                src={calendarIcon}
                            ></img>
                        </label>
                    </div>
                    {errors.birthday && (
                        <span className="error">
                            {errors.birthday.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="input-add-user margin-input d-flex flex-column">
                    <p style={{ marginTop: 0 }}>担当</p>
                    <Controller
                        name="in_charge"
                        control={control}
                        render={({ field: { value } }) => (
                            <Dropdown
                                className="dropdown-create-user1 worker-create-input"
                                controlClassName="dropdown-control-user"
                                options={tantouList}
                                value={{
                                    label: value?.label || '',
                                    value: value?.value || '',
                                }}
                                {...register(
                                    'in_charge',
                                    // createWorkerValidation(t).department_id()
                                )}
                                onChange={(newValue) => {
                                    if (
                                        value &&
                                        value.value === newValue.value
                                    ) {
                                        setValue('in_charge', undefined);
                                    } else {
                                        setValue('in_charge', newValue);
                                    }
                                }}
                                placeholder=""
                            />
                        )}
                    />
                    {errors.department_id && (
                        <span className="error">
                            {errors.department_id.message?.toString()}
                        </span>
                    )}
                </div>
                <div
                    className="modal-create-user-buttons create-button-worker"
                    style={{ marginTop: '14px' }}
                >
                    <button
                        className="modal-create-user-button1"
                        onClick={() => navigate('/workers')}
                        type="button"
                    >
                        戻る
                    </button>
                    <button
                        className="modal-create-user-button2"
                        // disabled={(newUser.email === "" || newUser.password === "" || newUser.password_confirmation === "" || newUser.worker_id === -1) ? true : false}
                        type="submit"
                    >
                        登録
                    </button>
                </div>
            </div>
        </form>
    );
};

export default WorkerCreate;
