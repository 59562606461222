import { FC } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { ListItemIcon, MenuItem, Select, Stack } from '@mui/material';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import DatePickerField from '../FormField/DatePicker';
import DateTimePickerField from '../FormField/DateTimePicker';
import useCreateScheduleDialog, {
    Props,
    ReceivedProps,
    SCHEDULER_COLORS,
} from './hook';
import CreateScheduleWrapper from './style';
import initStatusIcon from '../../../assets/icons/status/ic_init_fill.svg';
import progressStatusIcon from '../../../assets/icons/status/ic_progress_fill.svg';
import finishStatusIcon from '../../../assets/icons/status/ic_finish_fill.svg';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const checkBoxStyles = {
    checkmark: {
        background: '#3DDD74',
        color: 'white',
    },
    checkbox: {
        background: '#3DDD74',
        borderColor: '#3DDD74',
        width: '15px',
        margin: '0 12px 0 0',
        height: '15px',
    },
};

const CreateScheduleDialogLayout: FC<Props> = ({
    open,
    formik,
    editingId,
    workerOptions,
    setCurrentItemId,
    setOpenDeleteConfirmationDialog,
    setOpen,
}) => {
    const navigate = useNavigate();

    return (
        <BootstrapDialog
            className="customBootstrapDialog scheduleFormDialog"
            onClose={() => setOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <CreateScheduleWrapper>
                <DialogContent>
                    <div
                        className="description"
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        {editingId
                            ? 'スケジュール編集'
                            : 'スケジュール新規作成'}
                    </div>
                </DialogContent>

                <form onSubmit={formik.handleSubmit} className="filterBox">
                    <div className="filterBoxTop">
                        <div className="filterBoxCol">
                            <span className="fieldLabel">担当社員</span>
                            <div className="wrapSelectField">
                                <Autocomplete
                                    noOptionsText="該当なし"
                                    value={formik.values.worker_id}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            'worker_id',
                                            newValue,
                                        );
                                    }}
                                    options={workerOptions || []}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={
                                                formik.touched.worker_id &&
                                                !!formik.errors.worker_id
                                            }
                                            helperText={
                                                formik.touched.worker_id &&
                                                formik.errors.worker_id
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="filterBoxCol">
                            <span className="fieldLabel">タイトル</span>
                            <div className="wrapTextField">
                                <TextField
                                    fullWidth
                                    hiddenLabel
                                    name="title"
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                    error={
                                        formik.touched.title &&
                                        !!formik.errors.title
                                    }
                                    helperText={
                                        formik.touched.title &&
                                        formik.errors.title
                                    }
                                />
                            </div>
                        </div>

                        <div className="filterBoxCol">
                            <span className="fieldLabel">内容</span>
                            <div className="wrapTextField">
                                <TextField
                                    fullWidth
                                    hiddenLabel
                                    name="content"
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    onChange={formik.handleChange}
                                    value={formik.values.content}
                                    multiline
                                />
                            </div>
                        </div>

                        <div className="filterBoxCol">
                            <span className="fieldLabel">
                                <FormControl fullWidth>
                                    <RadioGroup
                                        row
                                        name="is_all_day"
                                        onChange={() => {
                                            formik.setFieldValue(
                                                'is_all_day',
                                                false,
                                            );
                                        }}
                                    >
                                        <FormControlLabel
                                            value={false}
                                            control={
                                                <Radio
                                                    checked={
                                                        !formik.values
                                                            .is_all_day
                                                    }
                                                />
                                            }
                                            label="日時（期間）"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </span>
                            <div
                                className={classNames(
                                    'inlineField',
                                    !formik.values.is_all_day
                                        ? ''
                                        : 'hiddenValue',
                                )}
                            >
                                <div className="wrapTextField">
                                    <DateTimePickerField
                                        name="start_date"
                                        placeholder=""
                                        locale="ja"
                                        formik={formik}
                                    />
                                </div>
                                <div className="divider">~</div>
                                <div className="wrapTextField">
                                    <DateTimePickerField
                                        name="end_date"
                                        placeholder=""
                                        locale="ja"
                                        formik={formik}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="filterBoxCol">
                            <span className="fieldLabel">
                                <FormControl fullWidth>
                                    <RadioGroup
                                        row
                                        name="is_all_day"
                                        onChange={() => {
                                            formik.setFieldValue(
                                                'is_all_day',
                                                true,
                                            );
                                        }}
                                    >
                                        <FormControlLabel
                                            value={false}
                                            control={
                                                <Radio
                                                    checked={
                                                        formik.values.is_all_day
                                                    }
                                                />
                                            }
                                            label="終日"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </span>
                            <div
                                className={classNames(
                                    'inlineField',
                                    formik.values.is_all_day
                                        ? ''
                                        : 'hiddenValue',
                                )}
                            >
                                <div className="wrapTextField">
                                    <DatePickerField
                                        name="start_date"
                                        placeholder=""
                                        locale="ja"
                                        formik={formik}
                                    />
                                </div>
                                <div className="divider">~</div>
                                <div className="wrapTextField">
                                    <DatePickerField
                                        name="end_date"
                                        placeholder=""
                                        locale="ja"
                                        formik={formik}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="filterBoxCol">
                            <span className="fieldLabel">
                                場所（営業所、住所など）
                            </span>
                            <div className="wrapTextField">
                                <TextField
                                    fullWidth
                                    hiddenLabel
                                    name="place"
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    onChange={formik.handleChange}
                                    value={formik.values.place}
                                />
                            </div>
                        </div>

                        <div className="filterBoxCol">
                            <span className="fieldLabel">参加社員</span>
                            <div className="wrapSelectField multipleSelect">
                                <Autocomplete
                                    multiple
                                    options={workerOptions || []}
                                    onChange={(_event, newValue) => {
                                        formik.setFieldValue(
                                            'worker_schedules_attributes',
                                            newValue,
                                        );
                                    }}
                                    getOptionLabel={(option: any) =>
                                        option.name
                                    }
                                    value={
                                        formik.values
                                            .worker_schedules_attributes
                                    }
                                    noOptionsText="該当なし"
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </div>
                        </div>
                        {/* <div className="my-[8px]">
                            <Stack direction="row" alignItems="center">
                                <Checkbox
                                    name="is_show_on_todo_list"
                                    styles={checkBoxStyles}
                                    checked={formik.values.is_show_on_todo_list}
                                    onChange={formik.handleChange}
                                />
                                <div className="text-[#666666] text-[14px]">
                                    TODOリスト
                                </div>
                            </Stack>
                        </div> */}
                        {/* <div className="my-[8px]">
                            <Stack
                                direction="row"
                                alignItems="center"
                                style={{ gap: '15px' }}
                            >
                                <div className="d-flex flex-row">
                                    <Checkbox
                                        name="targeted"
                                        styles={checkBoxStyles}
                                        checked={formik.values.targeted}
                                        onChange={formik.handleChange}
                                    />
                                    <div className="text-[#666666] text-[14px]">
                                        目標
                                    </div>
                                </div>
                                <div style={{ flexGrow: 1 }}>
                                    <Select
                                        name="status"
                                        className="select-status"
                                        style={{
                                            minWidth: '100%',
                                            background: '#f3f3f3',
                                        }}
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value="initial">
                                            <ListItemIcon>
                                                <img src={initStatusIcon} />
                                            </ListItemIcon>
                                        </MenuItem>
                                        <MenuItem value="in_progress">
                                            <ListItemIcon>
                                                <img src={progressStatusIcon} />
                                            </ListItemIcon>
                                        </MenuItem>
                                        <MenuItem value="completed">
                                            <ListItemIcon>
                                                <img src={finishStatusIcon} />
                                            </ListItemIcon>
                                        </MenuItem>
                                    </Select>
                                </div>
                            </Stack>
                        </div> */}
                        <div className="filterBoxCol">
                            <span className="fieldLabel">色</span>
                            <div className="wrapColorBox">
                                {SCHEDULER_COLORS.map((item, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        onClick={() => {
                                            formik.setFieldValue('color', item);
                                        }}
                                        className={classNames(
                                            formik.values.color === item
                                                ? 'active'
                                                : '',
                                        )}
                                        style={{
                                            backgroundColor: item,
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <DialogActions className="modal-create-plan-buttons d-flex flex-row">
                        <button
                            className="modal-create-plan-button1 d-flex flex-row"
                            onClick={() => {
                                setOpen(false);
                                // navigate(`/calendar`, {
                                //     state: {
                                //         editingId: null,
                                //     },
                                // });
                            }}
                        >
                            閉じる
                        </button>
                        {editingId && (
                            <button
                                type="button"
                                className="modal-create-user-button2 d-flex flex-row"
                                style={{
                                    background: '#FF5045',
                                }}
                                onClick={() => {
                                    setCurrentItemId(editingId);
                                    setOpenDeleteConfirmationDialog(true);
                                }}
                            >
                                削除
                            </button>
                        )}
                        <button
                            className="modal-create-plan-button2 d-flex flex-row"
                            type="submit"
                            // onClick={() =>
                            //     navigate(`/calendar`, {
                            //         state: {
                            //             editingId: null,
                            //         },
                            //     })
                            // }
                        >
                            登録
                        </button>
                    </DialogActions>
                </form>
            </CreateScheduleWrapper>
        </BootstrapDialog>
    );
};

const CreateScheduleDialog: FC<ReceivedProps> = (props) => (
    <CreateScheduleDialogLayout {...useCreateScheduleDialog(props)} />
);

export default CreateScheduleDialog;
