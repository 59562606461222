import Api from './api/scheduleApi';
import ApiSP from './api/scheduleApiSP';
import ApiTodo from './api/todoApi'
import pick from 'lodash/pick';

const getSchedules = async (input?: any) => {
    let data = pick(input, [
        'page',
        'per_page',
        'department_id',
        'position_id',
        'worker_id',
        'free_word',
    ]);
    const params = new URLSearchParams(data);

    try {
        return await Api()
            .get(`?${params.toString()}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};

const getSchedulesSP = async (input?: any) => {
    let data = pick(input, [
        'page',
        'per_page',
        'department_id',
        'position_id',
        'worker_id',
        'free_word',
    ]);
    const params = new URLSearchParams(data);

    try {
        return await ApiSP()
            .get(`?${params.toString()}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};

const getTodolist = async (input?: any) => {
    let data = pick(input, [
        'page',
        'per_page',
        'department_id',
        'position_id',
        'worker_id',
        'free_word',
    ]);
    const params = new URLSearchParams(data);

    try {
        return await ApiTodo()
            .get(`?${params.toString()}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};


const getSchedule = async (id: number) => {
    try {
        return await Api()
            .get(`${id}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};

const getScheduleSP = async (id: number) => {
    try {
        return await ApiSP()
            .get(`${id}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};


const getTarget = async (input?: any) => {
    let data = pick(input, [
        'included_date'
    ]);
    const params = new URLSearchParams(data);

    try {
        return await ApiSP()
            .get(`targeted?${params.toString()}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};

const createSchedule = async (payload: any) => {
    return await Api()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const createScheduleSP = async (payload: any) => {
    return await ApiSP()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateSchedule = async (id: number, payload: any) => {
    return await Api()
        .put(`${id}`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateScheduleSP = async (id: number, payload: any) => {
    return await ApiSP()
        .put(`${id}`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deleteSchedule = async (id: number) => {
    return await Api().delete(`${id}`);
};

const deleteScheduleSP = async (id: number) => {
    return await ApiSP().delete(`${id}`);
};


const scheduleService = {
    updateSchedule,
    getSchedules,
    getSchedule,
    createSchedule,
    deleteSchedule,
    deleteScheduleSP,
    updateScheduleSP,
    createScheduleSP,
    getScheduleSP,
    getSchedulesSP,
    getTodolist,
    getTarget,
};

export default scheduleService;
