import { axiosClient } from './quoteApi';
import pick from 'lodash/pick';

class InvoiceApi {
    createInvoice = (idToken: any, body: any) => {
        const url = `invoices`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.post(url, body, config);
    };

    getInvoiceDetail = (idToken: any, id: any) => {
        const url = `invoices/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };

    getLineInvoiceDetail = (idToken: any, id) => {
        let url = `${process.env.REACT_APP_SERVER_API_LINE}/line/api/invoices/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };

    updateInvoice = (idToken: any, body: any, id: any) => {
        const url = `invoices/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.put(url, body, config);
    };

    createInvoicePay = (idToken: any, body: any) => {
        const url = `invoice_deposits`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.post(url, body, config);
    };

    updateInvoicePay = (idToken: any, body: any) => {
        const url = `invoice_deposits`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.put(url, body, config);
    };

    deleteInvoicePay = (idToken: any, param: any) => {
        const url = `invoice_deposits/${param}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.delete(url, config);
    };

    createPaymentIntent = (idToken: any, id: any) => {
        const url = `${process.env.REACT_APP_SERVER_API_LINE}/line/api/invoices/${id}/create_payment_intent`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.post(url, undefined, config);
    };

    updatePaymentIntent = (idToken: any, id: any, payment_id) => {
        const url = `${process.env.REACT_APP_SERVER_API_LINE}/line/api/invoices/${id}/payment_success`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.put(url, { payment_id }, config);
    };

    updatePaymentIntentCash = (idToken: any, id: any, body) => {
        const url = `${process.env.REACT_APP_SERVER_API_LINE}/line/api/invoices/${id}/payment_success`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.put(url, body, config);
    };

    getInvoices = (idToken: any, body: any) => {
        let paramsData = pick(body, [
            'page',
            'per_page',
            'filter_value',
            'invoice_start_date',
            'invoice_end_date',
            'payment_start_date',
            'payment_end_date',
        ]);
        const params = new URLSearchParams(paramsData);
        const url = `invoices?${params.toString()}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };

    deleteInvoice = (idToken: any, invoiceId) => {
        const url = `invoices/${invoiceId}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.delete(url, config);
    };
}
const invoiceApi = new InvoiceApi();
export default invoiceApi;
