import {
    Button,
    Drawer,
    Upload,
    UploadProps,
    message,
    Spin,
    Input,
    DatePicker,
    ConfigProvider,
    Modal,
} from 'antd';
import {
    FocusEventHandler,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
    useRef,
} from 'react';
import jaJP from 'antd/es/locale/ja_JP';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CloseOutlined, DeleteOutlined } from '@mui/icons-material';
import cloudUploadIcon from 'assets/icons/cloudUpload.svg';
import uploadIcon from 'assets/icons/addImageFile.svg';
import warningIcon from 'assets/icons/WarningCircle.svg';
import { Controller, useForm } from 'react-hook-form';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import { QuoteStatusEnum } from 'utils/enums';
import { handleFilter } from 'utils/helper/filterDropdown';
import { QuoteFormStyles } from '../form/styled';
import styled from 'styled-components';
import { UploadChangeParam, RcFile, UploadFile } from 'antd/lib/upload';
import pdfFileIcon from 'assets/icons/pdf-file.svg';
import wordFileIcon from 'assets/icons/word-file-image.svg';
import excelFileIcon from 'assets/icons/excel-file-image.svg';
import quoteValidation from 'utils/validation/registers/quote';
import quoteApi from 'services/quoteApi';
import {
    alertSuccess,
    alertSuccessPopup,
    validateZipcode,
} from 'utils/helper/appHelper';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import { validZipCode } from 'utils/validation/registers/validator';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { fullWidthNumConvert } from 'pages/invoice';
import InputMask from 'react-input-mask-format';

const { Dragger } = Upload;
const { TextArea } = Input;
const digit = /[0-9０-９]/;
const mask = [digit, digit, digit, '-', digit, digit, digit, digit];
const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
}));
const typeOptions: SelectSearchOption[] = [
    { value: 'interior', name: '内装' },
    { value: 'exterior', name: '外装' },
    { value: 'water_area', name: '水回り' },
    { value: 'other', name: 'その他' },
];

export interface QuoteController {
    view: (id: number) => void;
}

const ModalQuoteCustom = styled(Drawer)`
    .ant-drawer-body {
        padding: 15px !important;
    }
    .ant-drawer-right > .ant-drawer-content-wrapper {
        margin: auto !important;
        width: 50% !important;
    }
`;

const ModalQuoteCustoms = styled(Modal)`
    .ant-modal-header {
        padding: 0px !important;
    }
    .ant-modal-content {
        min-height: 700px !important;
    }
`;

const UploadCustom = styled(Upload)<{ $isFull?: boolean }>`
    .ant-upload-list-picture-card-container:nth-child(
            ${(props) => (props.$isFull ? '3n' : '3n + 2')}
        ) {
        margin-right: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
        border: none;
        padding: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info {
        border-radius: 6.55px;
        border: 1px solid #dcdcdc;
    }
    .ant-upload-list {
        width: 332px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    .ant-upload-select-picture-card {
        order: -1;
    }
`;

interface IProps {}
const QuoteController = forwardRef<QuoteController, IProps>((props, ref) => {
    const {
        control,
        setValue,
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        watch,
        getValues,
        setError,
        reset,
        resetField,
    } = useForm();
    const { RangePicker } = DatePicker;
    const [companies, setCompanies]: any = useState([]);
    const [isCheckSend, setIsCheckSend] = useState(false);
    const [isAllowUpload, setAllowUpload] = useState(true);
    const [fileLength, setFileLength] = useState<number>(0);
    const [indexError, setIndexError] = useState(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [pdfFiles, setPdfFiles] = useState<any[] | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('id');
    const editable = searchParams.get('editable'); // determine wether that we can edit or view-only
    const navigate = useNavigate();
    const [listFile, setListFile]: any = useState([]);
    const [filesListPdf, setFilesListPdf]: any = useState([]);
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [isLoading, setIsLoading] = useState(false);
    const [pdfList, setPdfList]: any = useState([]);
    const [filePdfList, setFilePdfList]: any = useState([]);
    const [totalFile, setTotalFile]: any = useState([]);
    const [totalImageSize, setTotalImageSize] = useState<number>(0);
    const { t } = useTranslation();
    const findZipcodeTimer = useRef<NodeJS.Timeout>();
    const UPLOAD_LIMIT = 100;
    const [listType, setListType]: any = useState([]);
    const [sizeUploadLimitError, setSizeUploadLimitError] =
        useState<boolean>(false);
    useEffect(() => {
        if (sizeUploadLimitError) {
            message.error(
                `画像の合計サイズは ${UPLOAD_LIMIT}MB を超えてはなりません`,
            );
            setSizeUploadLimitError(false);
        }
    }, [sizeUploadLimitError]);
    const getDetail = async () => {
        setLoading(true);
        try {
            const res = await quoteApi.getSPQuoteDetail(user, id);
            if (res) {
                const data = res.data.quote;
                setValue('title', data?.title);
                setValue('note', data?.note);
                setValue('quote_type', data?.quote_type);
                setValue('address', data?.address);
                setValue('status', data?.status);
                setValue('content', data?.content);
                setValue('zipCode', data?.zipcode);
                // data?.desired_date &&
                //     setValue('dateTime', moment(data.desired_date));
                const arrDate: any = [];
                arrDate.push(moment(data?.desired_from_date));
                arrDate.push(moment(data?.desired_end_date));
                setValue('dateTime', arrDate);
                setValue('company_coop', data?.company.id);
                const fileArr: any = [];
                const pdfArr: any = [];
                let imageSize = 0;
                if (data?.images.length > 0) {
                    for (let i = 0; i < data?.images.length; i++) {
                        await fetch(data?.images[i].image_path)
                            .then((res) => res.blob())
                            .then((myBlob) => {
                                const myFile: any = new File(
                                    [myBlob],
                                    'image.jpeg',
                                    { type: myBlob.type },
                                );
                                fileArr.push({
                                    uid: `-${i + 1}`,
                                    name: myFile.name,
                                    url: data?.images[i].image_path,
                                    status: 'done',
                                    originFileObj: myFile,
                                    lastModified: myFile.lastModified,
                                    lastModifiedDate: myFile.lastModifiedDate,
                                    type: myFile.type,
                                    size: myFile.size,
                                });
                                imageSize += myFile.size;
                            });
                    }
                }
                if (data?.files) {
                    const arrName: any = [];
                    for (let i = 0; i < data?.files.length; i++) {
                        arrName.push(data?.files[i]?.file_path);
                        await fetch(data?.files)
                            .then((res) => res.blob())
                            .then((myBlob) => {
                                const myFile: any = new File(
                                    [myBlob],
                                    data?.files[i]?.file_path,
                                    {
                                        type: myBlob.type,
                                    },
                                );
                                if (myFile.name.includes('.pdf')) {
                                    listType.push('pdf');
                                } else if (myFile.name.includes('.xls')) {
                                    listType.push('xls');
                                } else if (myFile.name.includes('.doc')) {
                                    listType.push('doc');
                                }
                                pdfArr.push(myFile);
                            });
                    }
                }
                setValue('images', fileArr);
                setTotalImageSize((prev) => prev + imageSize);
                setListFile(fileArr);
                setFileLength(fileArr.length);
                setPdfFiles(data?.file_url);
                setFilesListPdf(pdfArr);
                setPdfList(pdfArr);
                setAllowUpload(data?.images?.length < 100);
            }
        } catch (error) {
            //
        } finally {
            setLoading(false);
        }
    };
    const wordLimit = 5000;
    const dummyRequest: UploadProps['customRequest'] = async ({
        file,
        onSuccess,
    }) => {
        setTimeout(() => {
            if (onSuccess) {
                onSuccess('ok');
            }
        }, 0);
    };

    const parsePostalCode = (zipCode: string) => {
        if (!zipCode) {
            setError('zipCode', {
                type: 'required',
                message: '郵便番号を入力してください。',
            });
            return;
        }

        const isZipcodeFormat = validateZipcode(zipCode);
        if (!isZipcodeFormat) {
            setError('zipCode', {
                type: 'validate',
                message:
                    '郵便番号は数字のみで、000-0000の形式でなければなりません。',
            });
            return;
        }

        if (findZipcodeTimer.current) {
            clearTimeout(findZipcodeTimer.current);
        }

        let foundAddress: string;
        const postalCode = require('japan-postal-code');
        postalCode.get(zipCode, function (address) {
            foundAddress = `${address?.prefecture}${address?.city}${address?.area}`;
        });

        findZipcodeTimer.current = setTimeout(() => {
            if (foundAddress) {
                setValue('address', foundAddress);
                clearErrors('address');
                clearErrors('zipCode');
            } else {
                setValue('address', '');
                setError('zipCode', {
                    type: 'validate',
                    message: '入力された郵便番号に該当する住所が存在しません。',
                });
            }
        }, 1000);
    };

    useEffect(() => {
        return () => {
            if (findZipcodeTimer.current) {
                clearTimeout(findZipcodeTimer.current);
            }
        };
    }, []);

    useEffect(() => {
        if (filesListPdf === null) {
            setTotalFile(filePdfList);
        } else {
            // console.log('first add file')
            setTotalFile(filesListPdf?.concat(filePdfList));
        }
    }, [filePdfList, filesListPdf]);

    const parseNamePdf = (file_path: any) => {
        const arrName: any = [];
        const string: any = decodeURIComponent(file_path);
        if (string !== null && string !== undefined) {
            for (let i = string?.length - 1; i > 0; i--) {
                if (string[i] !== '/') {
                    arrName.push(string[i]);
                } else {
                    break;
                }
            }
        }
        return arrName
            .slice()
            .reverse()
            .toString()
            .replaceAll(',', '')
            .split('.')[0];
    };

    const handleChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
        const loadFile = 100;
        setIndexError(0);
        const filteredPdfAfterUid = info?.fileList.filter((bItem) =>
            listFile?.some((aItem) => aItem.uid === bItem.uid),
        );
        const filteredPdfAfterRedundant = info?.fileList.filter(
            (bItem) => !listFile?.some((aItem) => aItem.uid === bItem.uid),
        );
        const filteredPdfAfterReMove: any =
            loadFile < filteredPdfAfterRedundant?.length &&
            filteredPdfAfterRedundant?.slice(0, loadFile > 1 ? loadFile : 1);

        if (info.file.status === 'done') {
            setTotalImageSize(
                (prevSize) => prevSize + (info.file.size as number),
            );
        }
        if (info.file.status === 'removed') {
            setTotalImageSize(
                (prevSize) => prevSize - (info.file.size as number),
            );
        }
        if (loadFile >= info?.fileList?.length) {
            setAllowUpload(info.fileList.length < 100);
            setValue('images', info.fileList);
            setFileLength(info?.fileList?.length);
            setListFile(info?.fileList);
        } else {
            setIndexError((index) => index + 1);
        }
        clearErrors('images');
    };

    const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
        const uploadingSizes = fileList.reduce((accu: number, curr: RcFile) => {
            return accu + curr.size;
        }, 0);
        setIndexError(0);
        const accumulatedImageSize = totalImageSize + uploadingSizes;
        const isImage =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'image/gif';
        if (!isImage) {
            message.error(
                'jpg、.jpeg、.png、.gif形式でアップロードしてください。',
            );
            return Upload.LIST_IGNORE;
        }

        const isUnderLimit = accumulatedImageSize < 1024 * 1024 * UPLOAD_LIMIT;
        if (!isUnderLimit) {
            setSizeUploadLimitError(true);
            return Upload.LIST_IGNORE;
        }

        return isImage && isUnderLimit;
    };

    const uploadButton = (
        <div className="text-[#5392F0] flex flex-col items-center">
            <img
                src={uploadIcon}
                className="w-[21px] h-[21px]"
                alt="upload image icon"
            />
            <div>写真 ({fileLength > 0 ? fileLength : 0}/100)</div>
        </div>
    );

    const dragProps: UploadProps = {
        name: 'file',
        beforeUpload(file: RcFile) {
            // const isPDF = file.type === 'application/pdf';
            // if (!isPDF) {
            //     message.error('PDF形式でアップロードしてください。');
            // }
            if (file.type === 'application/pdf') {
                listType.push('pdf');
            } else if (file.name.includes('xls')) {
                listType.push('xls');
            } else if (file.name.includes('doc')) {
                listType.push('doc');
            }
            const isLE10MB = file.size / 1024 / 1024 <= 10;
            if (!isLE10MB) {
                message.error('ファイルは10MBを超えてはいけません。');
            }

            if (isLE10MB) {
                setFilePdfList((filePdfList) => {
                    return [...filePdfList, file];
                });
            }
            return isLE10MB || Upload.LIST_IGNORE;
        },
        onChange(info) {
            const filteredPdfAfterRemove = filePdfList.filter((bItem) =>
                info?.fileList?.some((aItem) => aItem.uid === bItem.uid),
            );
            setFilePdfList(filteredPdfAfterRemove);
            setValue('file', info.file.originFileObj);
            setPdfList(info.fileList);
        },
        onDrop(e) {
            //
        },
        customRequest({ file, onSuccess }) {
            setTimeout(() => {
                if (onSuccess) {
                    onSuccess('ok');
                }
            }, 0);
        },
    };

    const fetchData = () => {
        // fetch detail then set into data
    };
    const handleClose = () => {
        clearErrors();
        setTotalImageSize(0);
        navigate('');
        setTotalFile([]);
        setFilePdfList([]);
        setListFile([]);
        setFileLength(0);
        setListType([]);
        reset();
        resetField('company_coop');
        setValue('company_coop', '');
    };

    const submitData = async (value: any, evt: any) => {
        // evt.preventDefault()
        if (value?.zipcode) {
            const isZipcodeFormat = validateZipcode(value?.zipcode);
            if (!isZipcodeFormat) {
                setError('zipCode', {
                    type: 'validate',
                    message:
                        '郵便番号は数字のみで、000-0000の形式でなければなりません。',
                });
                return;
            }
        }
        setIsLoading(true);
        let formData = new FormData();
        for (let i = 0; i < totalFile?.length; i++) {
            formData.append('files[]', totalFile[i]);
        }
        formData.append('title', '');
        formData.append('address', value?.address);
        for (let i = 0; i < value?.images.length; i++) {
            formData.append('images[]', value?.images[i].originFileObj);
        }

        formData.append('note', value?.note);
        formData.append('status', value?.status);
        formData.append('quote_type', value?.quote_type);
        formData.append('desired_date', value?.dateTime);
        formData.append('zipcode', value?.zipCode);
        formData.append('content', value?.content);
        isCheckSend && formData.append('is_send_message', 'true');
        try {
            const res = await quoteApi.updateQuote(user, formData, id);
            if (res) {
                alertSuccessPopup({
                    title: '見積書が正常に送信されました',
                    confirmButtonText: '閉じる',
                });
                setIsLoading(false);
                navigate(`/quote/list`);
                setValue('note', '');
                setValue('title', '');
                setValue('address', '');
                setValue('images', '');
                setListFile([]);
                setTotalImageSize(0);
                setValue('zipcode', '');
                setValue('desired_date', undefined);
                setValue('content', '');
                setValue('company_coop', '');
                setTotalFile([]);
                setFilePdfList([]);
            }
        } catch (error) {
            setIsLoading(false);
        }
        setIsCheckSend(false);
    };

    useEffect(() => {
        getListCompany();
        if (id) {
            getDetail();
        }
    }, [id]);

    useEffect(() => {
        indexError === 1 &&
            message.error('ファイルアップロードは100ファイルまでです。');
    }, [indexError]);

    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        }
    };

    const getListCompany = async () => {
        try {
            const res = await quoteApi.geAlltCompanies(user);
            const arrTmp: any = [];
            for (let i = 0; i < res.data.cooperative_companies.length; i++) {
                arrTmp.push({
                    value: res.data.cooperative_companies[i].company.id,
                    name: res.data.cooperative_companies[i].company.name,
                });
            }
            setCompanies(arrTmp);
        } catch (error) {
            console.log('error', error);
        }
    };
    return (
        <ModalQuoteCustoms
            width={600}
            title={
                <div className="flex justify-between items-center bg-[#215493] text-[white] py-[20px] px-[15px]">
                    <span>{editable ? '編集' : '詳細'}</span>
                    <Button shape="circle" type="text" onClick={handleClose}>
                        <CloseOutlined style={{ color: 'white' }} />
                    </Button>
                </div>
            }
            closable={false}
            destroyOnClose
            footer={null}
            open={Boolean(id)}
            onCancel={handleClose}
            // headerStyle={{ background: '#215493' }}
        >
            <ConfigProvider locale={jaJP}>
                {isLoading && (
                    <div
                        style={{
                            width: '100vw',
                            height: '100vh',
                            top: 0,
                            left: 0,
                            zIndex: 9999,
                        }}
                        className="position-fixed d-flex align-items-center justify-content-center"
                    >
                        <Spin className="w-[100px] h-[100px]" />
                    </div>
                )}
                <QuoteFormStyles>
                    <div className="quote-form-container">
                        {loading ? (
                            <div
                                style={{
                                    width: '100vw',
                                    height: '100vh',
                                    top: 0,
                                    left: 0,
                                    zIndex: 9999,
                                }}
                                className="position-fixed d-flex align-items-center justify-content-center"
                            >
                                <Spin className="w-[100px] h-[100px]" />
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit(submitData)}>
                                <div className="w-full flex flex-col gap-y-[14px]">
                                    <div className="flex flex-col w-full">
                                        <div className="flex items-center mb-3">
                                            <div className="mr-4 text-base">
                                                写真アップロード
                                            </div>
                                            <img
                                                src={warningIcon}
                                                alt="warning circle icon"
                                            />
                                            <div className="text-[#808080] pl-[3px]">
                                                {' '}
                                                1:1 スケール画像
                                            </div>
                                        </div>
                                        <div>
                                            <UploadCustom
                                                locale={{
                                                    uploading: 'アップロード中',
                                                }}
                                                accept=".jpg, .jpeg, .png, .gif"
                                                listType="picture-card"
                                                customRequest={dummyRequest}
                                                {...register('images', {
                                                    required:
                                                        '画像は最低1枚以上アップロードしてください。',
                                                })}
                                                disabled={!editable}
                                                onChange={handleChangeFile}
                                                beforeUpload={
                                                    handleBeforeUpload
                                                }
                                                fileList={listFile && listFile}
                                                multiple
                                                $isFull={
                                                    typeof fileLength ===
                                                        'number' &&
                                                    fileLength >= 100
                                                }
                                            >
                                                {isAllowUpload && uploadButton}
                                            </UploadCustom>
                                        </div>
                                        {errors.images && (
                                            <span className="error">
                                                {errors.images.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                    <div className="flex flex-col w-full">
                                        <Controller
                                            name="content"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({ field }) => (
                                                <div className="d-flex flex-column">
                                                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                        <span className="text-red mr-1">
                                                            *
                                                        </span>
                                                        依頼内容
                                                    </p>
                                                    <TextArea
                                                        autoComplete="off"
                                                        className="border-[1px] border-solid border-[#cdd1d5]"
                                                        rows={4}
                                                        {...register(
                                                            'content',
                                                            quoteValidation().content(),
                                                        )}
                                                        {...field}
                                                        disabled={!editable}
                                                        onBlur={
                                                            handleValidateWhitespace
                                                        }
                                                    />
                                                    {errors.content && (
                                                        <span className="error">
                                                            {errors.content.message?.toString()}
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </div>

                                    <div className="flex flex-col w-full">
                                        <div className="input-add-user d-flex flex-column">
                                            <p style={{ marginTop: 0 }}>
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                ステータス
                                            </p>
                                        </div>
                                        <Controller
                                            name="status"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <SelectSearch
                                                    options={statusOptions}
                                                    search
                                                    filterOptions={handleFilter}
                                                    {...field}
                                                    emptyMessage={'該当なし'}
                                                    placeholder=""
                                                    disabled={!editable}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="flex flex-col w-full">
                                        <div className="input-add-user d-flex flex-column">
                                            <p style={{ marginTop: 0 }}>
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                タイプ
                                            </p>
                                        </div>
                                        <Controller
                                            name="quote_type"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <input
                                                    autoComplete="off"
                                                    type="text"
                                                    className="textBox"
                                                    {...register(
                                                        'quote_type',
                                                        quoteValidation().type(),
                                                    )}
                                                    onBlur={
                                                        handleValidateWhitespace
                                                    }
                                                ></input>
                                            )}
                                        />
                                        {errors.type && (
                                            <span className="error">
                                                {errors.type.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                    <div className="flex flex-col w-full">
                                        <Controller
                                            name="dateTime"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <div className="d-flex flex-column w-full">
                                                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                        <span className="text-red mr-1">
                                                            *
                                                        </span>
                                                        おおよそ工事希望日
                                                    </p>
                                                    <RangePicker
                                                        // placeholder="日付選択"
                                                        {...register(
                                                            'dateTime',
                                                            quoteValidation()?.dateTime(),
                                                        )}
                                                        {...field}
                                                        disabled={!editable}
                                                        format={
                                                            'YYYY年MM月DD日'
                                                        }
                                                    />

                                                    {errors.dateTime && (
                                                        <span className="error">
                                                            {errors.dateTime.message?.toString()}
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </div>

                                    <div className="flex flex-col w-full">
                                        <Controller
                                            name="zipCode"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field, fieldState }) => {
                                                const {
                                                    name,
                                                    onChange,
                                                    onBlur,
                                                    ref,
                                                } = register('zipCode', {
                                                    required:
                                                        '郵便番号を入力してください。',
                                                    validate: {
                                                        regex: validZipCode(
                                                            'zipcode',
                                                            t,
                                                        ),
                                                    },
                                                });
                                                return (
                                                    <div className="d-flex flex-column">
                                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                            <span className="text-red mr-1">
                                                                *
                                                            </span>
                                                            郵便番号
                                                        </p>
                                                        <div className="flex gap-[10px]">
                                                            {/* <StyleMaskInput mask={mask} placeholder='999-9999' maskPlaceholder={null}
                                                                {...field}
                                                                onChange={(e) => {
                                                                    clearErrors('zipCode');
                                                                    field.onChange(e);
                                                                }}
                                                                onBlur={(evt) => {
                                                                    setValue('zipCode', fullWidthNumConvert(evt.target.value))
                                                                }}
                                                                disabled={
                                                                    !editable
                                                                }
                                                            /> */}
                                                            <Input
                                                                placeholder="000-0000"
                                                                {...register(
                                                                    'zipCode',
                                                                )}
                                                                onBlur={(
                                                                    evt,
                                                                ) => {
                                                                    let string =
                                                                        evt.target.value.replace(
                                                                            'ー',
                                                                            '-',
                                                                        );
                                                                    string =
                                                                        string.replace(
                                                                            '－',
                                                                            '-',
                                                                        );
                                                                    string =
                                                                        string.replace(
                                                                            '−',
                                                                            '-',
                                                                        );
                                                                    setValue(
                                                                        'zipCode',
                                                                        fullWidthNumConvert(
                                                                            string,
                                                                        ),
                                                                    );
                                                                }}
                                                                maxLength={8}
                                                                value={
                                                                    field.value
                                                                }
                                                                onChange={(
                                                                    evt,
                                                                ) => {
                                                                    // let string = evt.target.value.replace('ー', '-')
                                                                    // string = string.replace('－', '-')
                                                                    field.onChange(
                                                                        evt
                                                                            .target
                                                                            .value,
                                                                    );
                                                                    setValue(
                                                                        'zipCode',
                                                                        evt
                                                                            .target
                                                                            .value,
                                                                    );
                                                                    clearErrors(
                                                                        'zipCode',
                                                                    );
                                                                }}
                                                                disabled={
                                                                    !editable
                                                                }
                                                            />
                                                            {/* <PatternFormat
                                                                className="textBox"
                                                                format="###-####"
                                                                placeholder="000-0000"
                                                                mask="_"
                                                                {...field}
                                                                name={name}
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    clearErrors(
                                                                        'zipCode',
                                                                    );
                                                                    onChange(e);
                                                                }}
                                                                onBlur={onBlur}
                                                                getInputRef={
                                                                    ref
                                                                }
                                                                disabled={
                                                                    !editable
                                                                }
                                                            /> */}
                                                            <button
                                                                onClick={() => {
                                                                    if (
                                                                        fieldState.error
                                                                    ) {
                                                                        return;
                                                                    }
                                                                    parsePostalCode(
                                                                        getValues(
                                                                            'zipCode',
                                                                        ) as string,
                                                                    );
                                                                }}
                                                                type="button"
                                                                className="border-[1px] rounded-[8px] text-[white] bg-[#215493] border-[#215493] !text-[16px] !p-0 !w-[200px] disabled:opacity-60"
                                                                disabled={
                                                                    !editable
                                                                }
                                                            >
                                                                住所自動入力
                                                            </button>
                                                        </div>
                                                        {errors.zipCode && (
                                                            <span className="error">
                                                                {errors.zipCode.message?.toString()}
                                                            </span>
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>

                                    <div className="flex flex-col w-full">
                                        <Controller
                                            name="address"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <div className="d-flex flex-column">
                                                    <p className="text-base mb-[4px] font-medium text-[#344054]">
                                                        <span className="text-red mr-1">
                                                            *
                                                        </span>
                                                        工事住所
                                                    </p>
                                                    <input
                                                        autoComplete="off"
                                                        type="text"
                                                        className="textBox"
                                                        {...register(
                                                            'address',
                                                            quoteValidation().address(),
                                                        )}
                                                        onBlur={
                                                            handleValidateWhitespace
                                                        }
                                                        disabled={!editable}
                                                    ></input>
                                                    {errors.address && (
                                                        <span className="error">
                                                            {errors.address.message?.toString()}
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </div>

                                    <div className="flex flex-col w-full">
                                        <Controller
                                            name="company_coop"
                                            control={control}
                                            render={({ field }) => (
                                                <div className="d-flex flex-column">
                                                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                        他社連携会社
                                                    </p>
                                                    <SelectSearch
                                                        options={companies}
                                                        search
                                                        filterOptions={
                                                            handleFilter
                                                        }
                                                        {...register(
                                                            'company_coop',
                                                        )}
                                                        value={
                                                            getValues(
                                                                'company_coop',
                                                            )
                                                                ? getValues(
                                                                      'company_coop',
                                                                  )
                                                                : ''
                                                        }
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        emptyMessage={
                                                            '他社連携会社'
                                                        }
                                                        placeholder=""
                                                    />
                                                </div>
                                            )}
                                        />
                                        {errors.company_coop && (
                                            <span className="error !text-[14px]">
                                                {errors.company_coop.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                    <div className="flex flex-col w-full">
                                        <Controller
                                            name="note"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <p className="text-base mb-[4px] font-medium text-[#344054]">
                                                        注記
                                                    </p>
                                                    <textarea
                                                        {...field}
                                                        className="rounded-[2px] border-[1px] border-[#CDD1D5] p-[5px]"
                                                        rows={4}
                                                        maxLength={wordLimit}
                                                        disabled={!editable}
                                                    ></textarea>
                                                    <p className="text-right text-[#00000040]">
                                                        {field.value !==
                                                        undefined
                                                            ? field.value.length
                                                            : 0}
                                                        /{wordLimit}
                                                    </p>
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full mt-3 gap-[15px]">
                                    {editable && (
                                        <Dragger
                                            {...dragProps}
                                            multiple={true}
                                            // fileList={filesListPdf}
                                            accept=".pdf, .xls, .xlsx, .doc, .docx"
                                            showUploadList={false}
                                        >
                                            <div className="flex p-3 items-center justify-between">
                                                <div className="flex items-center">
                                                    <div className="mr-2">
                                                        <img
                                                            src={
                                                                cloudUploadIcon
                                                            }
                                                            alt="cloud upload icon"
                                                        />
                                                    </div>
                                                    <div className="text-left">
                                                        <p className="ant-upload-text !text-[14px]">
                                                            ファイルを選択してください
                                                        </p>
                                                        <p className="ant-upload-hint !text-[12px] max-w-[244px] pr-[12px]">
                                                            10MB以下のPDF、WORD、EXCELファイルを対応します。
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="text-[#215493] !text-[14px] border-solid border-[1px] border-[#215493] rounded flex items-center px-3 py-2">
                                                    選択
                                                </div>
                                            </div>
                                        </Dragger>
                                    )}
                                    {totalFile?.length > 0 &&
                                        totalFile?.map(
                                            (item: any, ind: any) => (
                                                <a
                                                    className="text-[#000000] !no-underline"
                                                    download
                                                >
                                                    <div className="flex bg-[#F8F8F8] rounded-xl p-2">
                                                        <div className="flex justify-between w-[100%] items-center">
                                                            <a
                                                                href={
                                                                    item?.name
                                                                }
                                                                className="text-[#000000] !no-underline flex items-center"
                                                                target="_blank"
                                                            >
                                                                <img
                                                                    src={
                                                                        listType[
                                                                            ind
                                                                        ] ===
                                                                        'pdf'
                                                                            ? pdfFileIcon
                                                                            : listType[
                                                                                  ind
                                                                              ] ===
                                                                              'xls'
                                                                            ? excelFileIcon
                                                                            : listType[
                                                                                  ind
                                                                              ] ===
                                                                              'doc'
                                                                            ? wordFileIcon
                                                                            : ''
                                                                    }
                                                                    alt="pdf file icon"
                                                                />
                                                                <div className="ml-3">
                                                                    <div className="text-ellipsis whitespace-nowrap overflow-hidden	max-w-[450px] ">
                                                                        {!item?.uid
                                                                            ? parseNamePdf(
                                                                                  item?.name,
                                                                              )
                                                                            : item?.name?.substring(
                                                                                  0,
                                                                                  item
                                                                                      ?.name
                                                                                      ?.length -
                                                                                      4,
                                                                              )}
                                                                    </div>
                                                                    <div className="text-[#0000008C]">
                                                                        {item?.file_path
                                                                            ? item?.size
                                                                            : Number(
                                                                                  item?.size /
                                                                                      1024,
                                                                              )?.toFixed(
                                                                                  1,
                                                                              ) +
                                                                              'KB'}
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            {editable && (
                                                                <div
                                                                    onClick={() => {
                                                                        const arrTypeTmp =
                                                                            listType;
                                                                        arrTypeTmp.filter(
                                                                            (
                                                                                item,
                                                                                index,
                                                                            ) =>
                                                                                index !==
                                                                                ind,
                                                                        );
                                                                        setListType(
                                                                            (
                                                                                oldVal,
                                                                            ) => {
                                                                                return oldVal.filter(
                                                                                    (
                                                                                        item,
                                                                                        index,
                                                                                    ) =>
                                                                                        index !==
                                                                                        ind,
                                                                                );
                                                                            },
                                                                        );
                                                                        setFilesListPdf(
                                                                            (
                                                                                oldVal,
                                                                            ) => {
                                                                                return oldVal.filter(
                                                                                    (
                                                                                        item,
                                                                                        index,
                                                                                    ) =>
                                                                                        index !==
                                                                                        ind,
                                                                                );
                                                                            },
                                                                        );
                                                                        setPdfList(
                                                                            (
                                                                                oldVal,
                                                                            ) => {
                                                                                return oldVal.filter(
                                                                                    (
                                                                                        item,
                                                                                        index,
                                                                                    ) =>
                                                                                        index !==
                                                                                        ind,
                                                                                );
                                                                            },
                                                                        );
                                                                        setFilePdfList(
                                                                            (
                                                                                oldVal,
                                                                            ) => {
                                                                                return oldVal.filter(
                                                                                    (
                                                                                        itemFile,
                                                                                        index,
                                                                                    ) =>
                                                                                        itemFile.uid !==
                                                                                        item.uid,
                                                                                );
                                                                            },
                                                                        );
                                                                        const filterFileRemove =
                                                                            totalFile?.filter(
                                                                                (
                                                                                    element,
                                                                                ) =>
                                                                                    item?.uid
                                                                                        ? element?.uid !==
                                                                                          item?.uid
                                                                                        : element?.lastModified !==
                                                                                          item?.lastModified,
                                                                            );
                                                                        setTotalFile(
                                                                            filterFileRemove,
                                                                        );
                                                                        // if (filterFileRemove.length > 0) {

                                                                        // } else {
                                                                        //     setTotalFile([])
                                                                        // }
                                                                    }}
                                                                >
                                                                    <DeleteOutlined />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </a>
                                            ),
                                        )}
                                </div>

                                {editable && (
                                    <div
                                        className="text-lg flex flex-col gap-[10px]"
                                        style={{
                                            marginTop: '14px',
                                        }}
                                    >
                                        <button
                                            className="modal-create-user-button2 !bg-[#02A509] d-flex flex-row !rounded-[2px] !w-[100%]"
                                            type="submit"
                                            onClick={() => {
                                                setIsCheckSend(true);
                                            }}
                                        >
                                            保存して送信
                                        </button>
                                        <button
                                            className="modal-create-user-button2 d-flex flex-row !rounded-[2px] !w-[100%]"
                                            type="submit"
                                        >
                                            保存
                                        </button>
                                        <button
                                            className="modal-create-user-button1 d-flex flex-row !rounded-[2px] !w-[100%]"
                                            type="button"
                                            onClick={handleClose}
                                        >
                                            キャンセル
                                        </button>
                                    </div>
                                )}
                            </form>
                        )}
                    </div>
                </QuoteFormStyles>
            </ConfigProvider>
        </ModalQuoteCustoms>
        // <ModalQuoteCustom
        //     // width={600}
        //     width="100%"
        //     style={{ textAlign: 'center' }}
        //     closable={false}
        //     visible={Boolean(id)}
        //     onClose={handleClose}
        //     destroyOnClose
        //     headerStyle={{ background: '#215493' }}
        //     title={
        //         <div className="flex justify-between items-center text-[white]">
        //             <span>{editable ? '編集' : '詳細'}</span>
        //             <Button shape="circle" type="text" onClick={handleClose}>
        //                 <CloseOutlined style={{ color: 'white' }} />
        //             </Button>
        //         </div>
        //     }
        // >
        // <ConfigProvider locale={jaJP}>
        //     <QuoteFormStyles>
        //         <div className="quote-form-container">
        //             {loading ? (
        //                 <div className="loadingSpinWrapper">
        //                     <Spin />
        //                 </div>
        //             ) : (
        //                 <form onSubmit={handleSubmit(submitData)}>
        //                     <div className="w-full flex flex-col gap-y-[14px]">
        //                         <div className="flex flex-col w-full">
        //                             <div className="flex items-center mb-3">
        //                                 <div className="mr-4 text-base">
        //                                     写真アップロード
        //                                 </div>
        //                                 <img
        //                                     src={warningIcon}
        //                                     alt="warning circle icon"
        //                                 />
        //                                 <div className="text-[#808080] pl-[3px]">
        //                                     {' '}
        //                                     1:1 スケール画像
        //                                 </div>
        //                             </div>
        //                             <div>
        //                                 <UploadCustom
        //                                     locale={{
        //                                         uploading: 'アップロード中',
        //                                     }}
        //                                     accept=".jpg, .jpeg, .png, .gif"
        //                                     listType="picture-card"
        //                                     customRequest={dummyRequest}
        //                                     {...register('images', {
        //                                         required:
        //                                             '画像は最低1枚以上アップロードしてください。',
        //                                     })}
        //                                     disabled={!editable}
        //                                     onChange={handleChangeFile}
        //                                     beforeUpload={
        //                                         handleBeforeUpload
        //                                     }
        //                                     fileList={listFile && listFile}
        //                                     multiple
        //                                     $isFull={
        //                                         typeof fileLength ===
        //                                             'number' &&
        //                                         fileLength >= 10
        //                                     }
        //                                 >
        //                                     {isAllowUpload && uploadButton}
        //                                 </UploadCustom>
        //                             </div>
        //                             {errors.images && (
        //                                 <span className="error">
        //                                     {errors.images.message?.toString()}
        //                                 </span>
        //                             )}
        //                         </div>

        //                         <div className="flex flex-col w-full">
        //                             <Controller
        //                                 name="content"
        //                                 rules={{ required: true }}
        //                                 control={control}
        //                                 render={({ field }) => (
        //                                     <div className="d-flex flex-column">
        //                                         <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
        //                                             <span className="text-red mr-1">
        //                                                 *
        //                                             </span>
        //                                             依頼内容
        //                                         </p>
        //                                         <TextArea
        //                                             autoComplete="off"
        //                                             className="border-[1px] border-solid border-[#cdd1d5]"
        //                                             rows={4}
        //                                             {...register(
        //                                                 'content',
        //                                                 quoteValidation().content(),
        //                                             )}
        //                                             {...field}
        //                                             disabled={!editable}
        //                                             onBlur={
        //                                                 handleValidateWhitespace
        //                                             }
        //                                         />
        //                                         {errors.content && (
        //                                             <span className="error">
        //                                                 {errors.content.message?.toString()}
        //                                             </span>
        //                                         )}
        //                                     </div>
        //                                 )}
        //                             />
        //                         </div>

        //                         <div className="flex flex-col w-full">
        //                             <div className="input-add-user d-flex flex-column">
        //                                 <p style={{ marginTop: 0 }}>
        //                                     <span className="text-red mr-1">
        //                                         *
        //                                     </span>
        //                                     ステータス
        //                                 </p>
        //                             </div>
        //                             <Controller
        //                                 name="status"
        //                                 control={control}
        //                                 rules={{ required: true }}
        //                                 render={({ field }) => (
        //                                     <SelectSearch
        //                                         options={statusOptions}
        //                                         search
        //                                         filterOptions={handleFilter}
        //                                         {...field}
        //                                         emptyMessage={'該当なし'}
        //                                         placeholder=""
        //                                         disabled={!editable}
        //                                     />
        //                                 )}
        //                             />
        //                         </div>

        //                         <div className="flex flex-col w-full">
        //                             <div className="input-add-user d-flex flex-column">
        //                                 <p style={{ marginTop: 0 }}>
        //                                     <span className="text-red mr-1">
        //                                         *
        //                                     </span>
        //                                     タイプ
        //                                 </p>
        //                             </div>
        //                             <Controller
        //                                 name="quote_type"
        //                                 control={control}
        //                                 rules={{ required: true }}
        //                                 render={({ field }) => (
        //                                     <SelectSearch
        //                                         options={typeOptions}
        //                                         search
        //                                         filterOptions={handleFilter}
        //                                         {...register(
        //                                             'quote_type',
        //                                             quoteValidation().type(),
        //                                         )}
        //                                         {...field}
        //                                         emptyMessage={'該当なし'}
        //                                         placeholder=""
        //                                         disabled={!editable}
        //                                     />
        //                                 )}
        //                             />
        //                             {errors.type && (
        //                                 <span className="error">
        //                                     {errors.type.message?.toString()}
        //                                 </span>
        //                             )}
        //                         </div>

        //                         <div className="flex flex-col w-full">
        //                             <Controller
        //                                 name="dateTime"
        //                                 control={control}
        //                                 rules={{ required: true }}
        //                                 render={({ field }) => (
        //                                     <div className="d-flex flex-column">
        //                                         <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
        //                                             <span className="text-red mr-1">
        //                                                 *
        //                                             </span>
        //                                             おおよそ工事希望日
        //                                         </p>
        //                                         <DatePicker
        //                                             placeholder="日付選択"
        //                                             {...register(
        //                                                 'dateTime',
        //                                                 quoteValidation()?.dateTime(),
        //                                             )}
        //                                             {...field}
        //                                             disabled={!editable}
        //                                             format={
        //                                                 'YYYY年MM月DD日'
        //                                             }
        //                                         />

        //                                         {errors.dateTime && (
        //                                             <span className="error">
        //                                                 {errors.dateTime.message?.toString()}
        //                                             </span>
        //                                         )}
        //                                     </div>
        //                                 )}
        //                             />
        //                         </div>

        //                         <div className="flex flex-col w-full">
        //                             <Controller
        //                                 name="zipCode"
        //                                 control={control}
        //                                 rules={{ required: true }}
        //                                 render={({ field, fieldState }) => {
        //                                     const {
        //                                         name,
        //                                         onChange,
        //                                         onBlur,
        //                                         ref,
        //                                     } = register('zipCode', {
        //                                         required:
        //                                             '郵便番号を入力してください。',
        //                                         validate: {
        //                                             regex: validZipCode(
        //                                                 'zipcode',
        //                                                 t,
        //                                             ),
        //                                         },
        //                                     });
        //                                     return (
        //                                         <div className="d-flex flex-column">
        //                                             <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
        //                                                 <span className="text-red mr-1">
        //                                                     *
        //                                                 </span>
        //                                                 郵便番号
        //                                             </p>
        //                                             <div className="flex gap-[10px]">
        //                                                 <PatternFormat
        //                                                     className="textBox"
        //                                                     format="###-####"
        //                                                     placeholder="000-0000"
        //                                                     mask="_"
        //                                                     {...field}
        //                                                     name={name}
        //                                                     onChange={(
        //                                                         e,
        //                                                     ) => {
        //                                                         clearErrors(
        //                                                             'zipCode',
        //                                                         );
        //                                                         onChange(e);
        //                                                     }}
        //                                                     onBlur={onBlur}
        //                                                     getInputRef={
        //                                                         ref
        //                                                     }
        //                                                     disabled={
        //                                                         !editable
        //                                                     }
        //                                                 />
        //                                                 <button
        //                                                     onClick={() => {
        //                                                         if (
        //                                                             fieldState.error
        //                                                         ) {
        //                                                             return;
        //                                                         }
        //                                                         parsePostalCode(
        //                                                             getValues(
        //                                                                 'zipCode',
        //                                                             ) as string,
        //                                                         );
        //                                                     }}
        //                                                     type="button"
        //                                                     className="border-[1px] rounded-[8px] text-[white] bg-[#215493] border-[#215493] !text-[16px] !p-0 !w-[200px] disabled:opacity-60"
        //                                                     disabled={
        //                                                         !editable
        //                                                     }
        //                                                 >
        //                                                     住所自動入力
        //                                                 </button>
        //                                             </div>
        //                                             {errors.zipCode && (
        //                                                 <span className="error">
        //                                                     {errors.zipCode.message?.toString()}
        //                                                 </span>
        //                                             )}
        //                                         </div>
        //                                     );
        //                                 }}
        //                             />
        //                         </div>

        //                         <div className="flex flex-col w-full">
        //                             <Controller
        //                                 name="address"
        //                                 control={control}
        //                                 rules={{ required: true }}
        //                                 render={({ field }) => (
        //                                     <div className="d-flex flex-column">
        //                                         <p className="text-base mb-[4px] font-medium text-[#344054]">
        //                                             <span className="text-red mr-1">
        //                                                 *
        //                                             </span>
        //                                             工事住所
        //                                         </p>
        //                                         <input
        //                                             autoComplete="off"
        //                                             type="text"
        //                                             className="textBox"
        //                                             {...register(
        //                                                 'address',
        //                                                 quoteValidation().address(),
        //                                             )}
        //                                             onBlur={
        //                                                 handleValidateWhitespace
        //                                             }
        //                                             disabled={!editable}
        //                                         ></input>
        //                                         {errors.address && (
        //                                             <span className="error">
        //                                                 {errors.address.message?.toString()}
        //                                             </span>
        //                                         )}
        //                                     </div>
        //                                 )}
        //                             />
        //                         </div>

        //                         <div className="flex flex-col w-full">
        //                             <Controller
        //                                 name="note"
        //                                 control={control}
        //                                 render={({ field }) => (
        //                                     <>
        //                                         <p className="text-base mb-[4px] font-medium text-[#344054]">
        //                                             注記
        //                                         </p>
        //                                         <textarea
        //                                             {...field}
        //                                             className="rounded-[2px] border-[1px] border-[#CDD1D5] p-[5px]"
        //                                             rows={4}
        //                                             maxLength={100}
        //                                             disabled={!editable}
        //                                         ></textarea>
        //                                         <p className="text-right text-[#00000040]">
        //                                             {field.value !==
        //                                             undefined
        //                                                 ? field.value.length
        //                                                 : 0}
        //                                             /{wordLimit}
        //                                         </p>
        //                                     </>
        //                                 )}
        //                             />
        //                         </div>
        //                     </div>

        //                     <div className="flex flex-col w-full mt-3 gap-[15px]">
        //                         {editable && (
        //                             <Dragger
        //                                 {...dragProps}
        //                                 multiple={true}
        //                                 // fileList={filesListPdf}
        //                                 accept=".pdf"
        //                                 showUploadList={false}
        //                             >
        //                                 <div className="flex p-3 items-center">
        //                                     <div className="mr-2">
        //                                         <img
        //                                             src={cloudUploadIcon}
        //                                             alt="cloud upload icon"
        //                                         />
        //                                     </div>
        //                                     <div className="text-left">
        //                                         <p className="ant-upload-text !text-[14px]">
        //                                             ファイルを選択してください
        //                                         </p>
        //                                         <p className="ant-upload-hint !text-[12px] max-w-[244px] pr-[12px]">
        //                                             10MB以下のPDF、WORD、EXCELファイルを対応します。
        //                                         </p>
        //                                     </div>
        //                                     <div className="text-[#215493] !text-[14px] border-solid border-[1px] border-[#215493] rounded flex items-center px-3 py-2">
        //                                         選択
        //                                     </div>
        //                                 </div>
        //                             </Dragger>
        //                         )}
        //                         {totalFile?.length > 0 &&
        //                             totalFile?.map((item: any) => (
        //                                 <a
        //                                     className="text-[#000000] !no-underline"
        //                                     download
        //                                 >
        //                                     <div className="flex bg-[#F8F8F8] rounded-xl p-2">
        //                                         <div className="flex justify-between w-[100%] items-center">
        //                                             <a
        //                                                 href={
        //                                                     item?.file_path
        //                                                 }
        //                                                 className="text-[#000000] !no-underline flex items-center"
        //                                                 target="_blank"
        //                                             >
        //                                                 <img
        //                                                     src={
        //                                                         pdfFileIcon
        //                                                     }
        //                                                     alt="pdf file icon"
        //                                                 />
        //                                                 <div className="ml-3">
        //                                                     <div className="text-ellipsis whitespace-nowrap overflow-hidden	 max-w-[400px] ">
        //                                                         {item?.file_path
        //                                                             ? parseNamePdf(
        //                                                                   item?.file_path,
        //                                                               )
        //                                                             : item?.name?.substring(
        //                                                                   0,
        //                                                                   item
        //                                                                       ?.name
        //                                                                       ?.length -
        //                                                                       4,
        //                                                               )}
        //                                                         . PDF
        //                                                     </div>
        //                                                     <div className="text-[#0000008C]">
        //                                                         {item?.file_path
        //                                                             ? item?.size
        //                                                             : Number(
        //                                                                   item?.size /
        //                                                                       1024,
        //                                                               )?.toFixed(
        //                                                                   1,
        //                                                               ) +
        //                                                               'KB'}
        //                                                     </div>
        //                                                 </div>
        //                                             </a>
        //                                             {editable && (
        //                                                 <div
        //                                                     onClick={() => {
        //                                                         const filterFileRemove =
        //                                                             totalFile?.filter(
        //                                                                 (
        //                                                                     element,
        //                                                                 ) =>
        //                                                                     item?.uid
        //                                                                         ? element?.uid !==
        //                                                                           item?.uid
        //                                                                         : element?.id !==
        //                                                                           item?.id,
        //                                                             );
        //                                                         setTotalFile(
        //                                                             filterFileRemove,
        //                                                         );
        //                                                     }}
        //                                                 >
        //                                                     <DeleteOutlined />
        //                                                 </div>
        //                                             )}
        //                                         </div>
        //                                     </div>
        //                                 </a>
        //                             ))}
        //                     </div>

        //                     {editable && (
        //                         <div
        //                             className="text-lg flex flex-col gap-[10px]"
        //                             style={{
        //                                 marginTop: '14px',
        //                             }}
        //                         >
        //                             <button
        //                                 className="modal-create-user-button2 !bg-[#02A509] d-flex flex-row !rounded-[2px] !w-[100%]"
        //                                 type="submit"
        //                                 onClick={() => {
        //                                     setIsCheckSend(true);
        //                                 }}
        //                             >
        //                                 保存して送信
        //                             </button>
        //                             <button
        //                                 className="modal-create-user-button2 d-flex flex-row !rounded-[2px] !w-[100%]"
        //                                 type="submit"
        //                             >
        //                                 保存
        //                             </button>
        //                             <button
        //                                 className="modal-create-user-button1 d-flex flex-row !rounded-[2px] !w-[100%]"
        //                                 type="button"
        //                                 onClick={handleClose}
        //                             >
        //                                 キャンセル
        //                             </button>
        //                         </div>
        //                     )}
        //                 </form>
        //             )}
        //         </div>
        //     </QuoteFormStyles>
        // </ConfigProvider>
        // </ModalQuoteCustom>
    );
});
const StyleMaskInput = styled(InputMask)({
    padding: '5px',
    border: '1px solid rgb(205, 209, 213)',
    width: '70%',
});
export default QuoteController;
