import { ContactStyles } from "./styled"

const SPContact = () => {
  return (
    <ContactStyles>
        <div className="innerWrap">
          <img src="/img/carbon_phone-filled.png" alt="phone" />
          <div>コンアフェット株式会社</div> 
          <div>事業本部名古屋市北区福徳町2-57</div>
          <div className="tel_fax">
            <a href="tel:+052-982-7739">TEL 052-982-7739</a>
          </div>
          <div className="tel_fax">
            <a href="#">FAX 052-982-7719</a>
          </div>
        </div>
    </ContactStyles>
  )
}

export default SPContact;
